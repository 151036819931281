import { Icon } from "@quantumcast/ui";
import { useSelector } from "@xstate/react";
import { Fragment, useContext } from "react";

import { t } from "../../../../global/Localization/translation";
import Cue from "./Cue/Cue";
import { selectors } from "./cueMachine";

import { CueStateContext } from "./CueStateContext";
const cueRowStyles = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr 30px",
  gridTemplateAreas: `
      "leftColumn rightColumn actions"
      "leftError rightError noError"
    `,
};

const { selectCues, selectErrors } = selectors;

const CueList = ({ fileId, isReadOnly = false, uniqueName }) => {
  const { cueService } = useContext(CueStateContext);
  const errors = useSelector(cueService, selectErrors);
  const cues = useSelector(cueService, selectCues);
  const cuePairs = [
    ["CUE_IN", "CUE_OUT"],
    ["FADE_IN", "FADE_OUT"],
    ["START_VOICE"],
    ["START_NEXT"],
  ];

  return (
    <div
      onClick={() => {
        if (isReadOnly) {
          cueService.send({ type: "open" });
        }
      }}
    >
      {cuePairs.map((pair, rowIndex) => {
        const groupedCue = pair.map((type) =>
          cues.find((cue) => cue.type === type)
        );
        const canDelete =
          !["CUE_IN", "CUE_OUT"].includes(groupedCue?.[0]?.type) &&
          !["CUE_IN", "CUE_OUT"].includes(groupedCue?.[1]?.type);
        const showDeleteAction = !isReadOnly;

        const hasValues = groupedCue.filter(Boolean).length !== 0;

        if (!hasValues) {
          return null;
        }

        return (
          <div style={cueRowStyles} key={rowIndex}>
            {groupedCue.filter(Boolean).map((cue, columnIndex) => {
              const cueIndex = cues.findIndex((item) => item.type === cue.type);
              const fieldErrors = errors?.fieldErrors?.[`${cueIndex}`];
              const showFieldErrors = Boolean(fieldErrors) && !isReadOnly;
              const errorPostions = ["leftError", "rightError"];
              const cuePostions = ["leftColumn", "rightColumn"];
              return (
                <Fragment key={columnIndex}>
                  <div style={{ gridArea: cuePostions[columnIndex] }}>
                    <Cue
                      key={cue.id}
                      fileId={fileId}
                      cue={cue}
                      canDelete={false}
                      isReadOnly={isReadOnly}
                      uniqueName={uniqueName}
                      isInvalid={Boolean(fieldErrors)}
                    />
                  </div>
                  {showFieldErrors ? (
                    <div
                      style={{
                        gridArea: errorPostions[columnIndex],
                        color: "var(--brand-danger)",
                        paddingLeft: "calc(var(--spacing) * 1)",
                        marginBottom: Boolean(fieldErrors)
                          ? "calc(var(--spacing) * 3)"
                          : 0,
                      }}
                      className="cue__data"
                    >
                      {fieldErrors.map((error, errorIndex) => (
                        <div key={errorIndex}>{error}</div>
                      ))}
                    </div>
                  ) : null}
                </Fragment>
              );
            })}
            {showDeleteAction ? (
              <div className="cue__action" style={{ gridArea: "actions" }}>
                {canDelete ? (
                  <div
                    className="cue__delete"
                    tabIndex={0}
                    onClick={() =>
                      // @ts-expect-error
                      cueService.send({ type: "removeCuePair", pair })
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        // @ts-expect-error
                        cueService.send({ type: "removeCuePair", pair });
                      }
                    }}
                  >
                    <Icon.IonIcons5.IoTrashBinSharp
                      title={t("file.cues.delete")}
                    />
                  </div>
                ) : (
                  <div className="cue__delete cue__delete--forbidden">
                    <Icon.IonIcons5.IoTrashBinSharp
                      title={t("file.cues.delete")}
                    />
                  </div>
                )}
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
export { CueList };
