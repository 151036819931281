import { z } from "zod";

const pad = (time: number) => {
  return time.toString().padStart(2, "0");
};

const durationRegex = /^(?:(\d{1,2}):)?(\d{1,2}):(\d{1,2})(?:\.(\d{1,3}))?$/;
const stringDurationToMilliseconds = z.string().regex(durationRegex).transform((duration) => {

  const match = duration.match(durationRegex);
  if (match) {
    const hours = parseInt(match[1], 10) || 0;
    const minutes = parseInt(match[2], 10);
    const seconds = parseInt(match[3], 10);
    const milliseconds = parseInt(match[4], 10) || 0;
    return (hours * 3600 + minutes * 60 + seconds) * 1000 + milliseconds;
  } else {
    throw new Error(`Invalid duration: ${duration}`);
  }
});

const transformMillisecondsToStringDuration = (milliseconds: number) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  const millisecondsStr = milliseconds.toString().slice(-3);

  let formattedTime = '';
  if (hours > 0) {
    formattedTime += `${hours.toString().padStart(2, '0')}:`;
  }
  formattedTime += `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${millisecondsStr}`;
  return formattedTime;

}

const millisecondsToStringDuration = z.number().transform(transformMillisecondsToStringDuration)

/**
 * @deprecated please use `transformMillisecondsToStringDuration`
 */
const msToTime = (time: number) => {
  const ms = time % 1000;
  time = (time - ms) / 1000;
  const secs = time % 60;
  time = (time - secs) / 60;
  const mins = time % 60;
  const hrs = (time - mins) / 60;

  return `${hrs > 0 ? `${pad(hrs)}:` : ""}${pad(mins)}:${pad(secs)}`;
}

/**
 * @deprecated please use `stringDurationToMilliseconds`
 */
const timeToMs = (time: string): number | null => {
  const regex = new RegExp(/^(([01]\d|2[0-3]):)?(?:[0-5]\d):(?:[0-5]\d)$/);
  if (!regex.test(time)) {
    return null;
  }

  const parts = time
    .split(":")
    .map((part) => parseInt(part))
    .reverse();
  let ms = 0;
  parts.forEach((part, index) => {
    switch (index) {
      case 0:
        ms = ms + part * 1000;
        break;
      case 1:
        ms = ms + part * 60 * 1000;
        break;
      case 2:
        ms = ms + part * 60 * 60 * 1000;
        break;
      default:
        break;
    }
  });
  return ms;
}

export {
  durationRegex,
  stringDurationToMilliseconds,
  millisecondsToStringDuration,
  transformMillisecondsToStringDuration,
  timeToMs,
  msToTime
}