import { File } from "./domain";
import { AmbiguousMetaDatum, MetaDatumSet, RawFileMetaDatum } from "./types";

export const getOverlapMetaData = (
  files: File[],
  selection: string[]
): AmbiguousMetaDatum[] => {
  const selectedFiles = files.filter((file) => selection.includes(file.id));
  const allKeys = new Set(
    selectedFiles.flatMap((file) => file.metaData.map((datum) => datum.key))
  );

  // Collect all overlapping keys
  const overlappingKeys: Set<string> = new Set();
  allKeys.forEach((key) => {
    const keys = selectedFiles
      .map((file) => file.metaData.flatMap((datum) => datum.key))
      .flat()
      .filter((it) => it === key);
    if (keys.length === selectedFiles.length) {
      overlappingKeys.add(key);
    }
  });

  const fields: AmbiguousMetaDatum[] = [];
  allKeys.forEach((key) => {
    const data = selectedFiles
      .flatMap((file) => file.metaData)
      .filter((datum) => datum.key === key);

    const { id: _, ...usedDatum } = data[0];
   if (isIdenticalMetaDatum(data)) {
      // Add full matches
      fields.push(usedDatum);
    } else if (isIdenticalTypes(data)) {
      // Add matches with different values
      if (usedDatum.type === "TEXT") {
        fields.push({ ...usedDatum, value: null });
      } else if (usedDatum.type === "TEXT_LIST") {
        const allValues = new Set(
          data.map((datum) => Object.keys(datum.value)).flat()
        );

        // Divide common and additional values into two lists
        const commonValue = new Set<string>();
        const additionalValue = new Set<string>();
        allValues.forEach((value) => {
          const matches = data.filter((datum) =>
            Object.keys(datum.value).includes(value)
          );
          if (matches.length === data.length) {
            commonValue.add(value);
          } else {
            additionalValue.add(value);
          }
        });

        fields.push({
          ...usedDatum,
          value: setToObject(commonValue),
          additionalValue: setToObject(additionalValue),
        });
      }
    } else {
      // Add matches with only common keys
      fields.push({ ...usedDatum, type: null, value: null });
    }
  });
  return fields;
};

const isIdenticalMetaDatum = (data: RawFileMetaDatum[]) => {
  return isIdenticalTypes(data) && isIdenticalValues(data);
};

const isIdenticalTypes = (data: RawFileMetaDatum[]) => {
  const types = data.map((datum) => datum.type);
  return isIdentical(types);
};

const isIdenticalValues = (data: RawFileMetaDatum[]) => {
  const values = data.map((datum) => {
    if (typeof datum.value !== "string") {
      const sortedValue = Object.keys(datum.value).sort();
      return JSON.stringify(sortedValue);
    }
    return datum.value;
  });
  return isIdentical(values);
};

const isIdentical = (list: any[]) => {
  return list.every((item, _, array) => item === array[0]);
};

const setToObject = (set: Set<string>): MetaDatumSet => {
  return Object.fromEntries([...set].map((item) => [item, item]));
};
