import * as React from "react";
import { BrowserRouter } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

const Router = (props: Props) => {
  // @ts-expect-error
  return <BrowserRouter>{props.children}</BrowserRouter>;
};

export default Router;
