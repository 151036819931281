import { HttpError } from "@klw/fetch/lib/types";
import { Option } from "@quantumcast/ui";
import { assign, createMachine } from 'xstate';
import { t } from "../../../../../global/Localization/translation";
import type { AmbiguousMetaDatum, MetaDatumSet, MetaDatumType, RawFileMetaDatum } from "../../../types";
const EMPTY_TEXT_LIST = undefined;
const EMPTY_TEXT = "";

export type MetaDatumContext = {
  error: string
} & ({
  datum: RawFileMetaDatum
  intent: 'update'
} | {
  datum: AmbiguousMetaDatum
  intent: 'multi-update'
} | {
  datum: Partial<RawFileMetaDatum> & {
    type: RawFileMetaDatum['type']
  }
  intent: 'create'
})


const metaDatumMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QFswBcCGARDaCuyAdAJYB2xaAxIqAA4D2sFx9pNIAHogLQBMAnAEZCABhG8A7CIn8BAFn4SArEoA0IAJ48lckYV4AORUNlyAbLyW8AvtfWpMOfEQDGAJzC4wAMQA29AHcSCF8wShcACwxSGHYGJjQWNiRORF4AZiVCJQNpdIkDXPSzdLk5dS0Ebhz+fTMJMzMxQXTBIwlbe3RsXAJCPFoILz9A4NDKCDBQtDA4xmZWdi4qwRbCAp102V5zAwVeCp4JQn4DHJFSsQylQUklTpAHHud+weH-IOIQsMjo2JT4gtkqBloIbvpimIDOleI0yvwzIcqq11iIzHteGiESoREp0g8nk4+gMhjMRkESbgyFAJqwwCRSAA3egAa3phN6REpZI+r1J1IQZGZLiprAA2iIALpzBJJJZpERyUTo6S3YoSORGA6aHhgvRyXi3C4FXGWCT4uyPbpErlvHmjSbTam00j0oWs9nWzl894OqboAXukWJcVSmVA+XIqyEMw1SSKvGwgxI46CfjpPaKYr8dMawQEr0vbk+XmOgMxShgNxuehuQi0Xy4ABmtaIHKLdpLfqdMUFTPowaSEulAPmIeBqRWO0IpT2Zl0xXVMKR3EEcmE0jEJXyDREBljtktpHok3gKXbfTIFHD48j3HytVOmSUWaUEmUOhXeMfeNW6WKZSZPwBaON67iePanzfDecopMsZi1P+-B7miGSGP+EhfkYdQNE0IgtG0iggc8fTgb6AQwYscGIHIWRIShFgwtCxRqDqVQNMIvACLI-BWK0vFmMRNo+pBYyzKOspUSCiD5OsaaCLG6ZCEoIj8OUbF8EqBhcSoeK5O+-75paF62qSXYUna1KUROyzwjOa4voq2l7joiIaW0wjKf+hp7hmFhCd6xbkoQZaJDE1l3pkxwIXuabQgiMJyJh7lJTONw6IamKWAaHTGYWxKdsFoWQBF1HIoYMbIXs37QgYaasZU2E3MhD7mAhOZyAFHZmeSpXScidWVbFT4JTsyWVPeMKEIUDlrgU75poJeWgc4fWTtwuhZDFdUjSUY0rsx+htJi2lCPheyHtYQA */
  createMachine({
    context: {
      datum: {
        type: "TEXT",
      },
      error: "",
      intent: "create",
    },
    tsTypes: {} as import("./metaDatumMachine.typegen").Typegen0,
    schema: {
      context: {} as MetaDatumContext,
      services: {} as {
        'updating': {
          data: void | HttpError
        }
      },
      events: {} as
        { type: "delete"; } | {
          type: "change";
          payload: {
            name: "type"
            option: { value: MetaDatumType };
          } |
          {
            name: "key"
            option: Option;
          } |
          {
            name: "value";
            option: Option | Option[];
          };
        },
    },
    preserveActionOrder: true,
    id: "metaDatum",
    initial: "init",
    states: {
      init: {
        always: [
          {
            cond: "intentIsCreate",
            target: "createFlow",
          },
          {
            cond: "intentIsUpdate",
            target: "updateFlow",
          },
          {
            cond: "intentIsMultiUpdate",
            target: "updateFlow",
          },
        ],
      },
      createFlow: {
        description: "if a new metaDatum should be created, there is no need to persist every change on the server, the createFlow keeps values in memory or discards them… there is no need to delete this metaDatum",
        initial: "idle",
        states: {
          idle: {
            on: {
              change: {
                actions: ["update", "updateParent"],
                description: "only update if value is changed",
              },
            },
          },
        },
      },
      updateFlow: {
        description: "if metaDatum already exists it can be updated, deleted or changes are directly send to the server",
        initial: "idle",
        states: {
          idle: {
            on: {
              delete: {
                actions: 'clearError',
                target: "deleting",
              },
              change: {
                actions: "update",
                description: "only update if value is changed and is valid metaDatum",
                target: "updating",
              },
            },
          },
          updating: {
            invoke: {
              src: "updating",
              onDone: [
                {
                  target: "idle",
                  actions: ['setError'],
                  cond: 'requestReturnedError'
                },
                {
                  target: "idle",
                  actions: ['clearError'],
                },
              ],
              onError: {
                // TODO: handle Promise Rejection
                actions: []
              }
            },
          },
          deleting: {
            invoke: {
              src: "deleteDatum",
              onDone: [
                {
                  target: "deleted",
                },
              ],
              onError: [
                {
                  target: "idle",
                },
              ],
            },
          },
          deleted: {
            type: "final",
          },
        },
      },
    },
  }, {
    actions: {
      setError: assign({
        error: (context, event) => {
          return t("error.datum.update", {
            error: t(`error.http.${typeof event.data !== "undefined" ? event.data?.type || "UNKNOWN" : "UNKNOWN"}`),
          })
        }

      }),
      clearError: assign({ error: (context, event) => '' }),
      updateParent: () => { },
      update: assign({
        datum: (context, event) => {

          /**
           * if the same `type` was send again and therefor it no futher 
           * action is needed, everything stays as before eg. value will 
           * not be resetted
           */
          if (event.payload.name === "type" && event.payload.option.value === context.datum.type) {
            return context.datum;
          }

          let newDatum = {
            ...context.datum
          };

          if (event.payload.name === "type") {

            // determine if we change from "TEXT" to "TEXT_LIST"
            const isTags = event.payload.option.value === "TEXT_LIST";

            newDatum['type'] = event.payload.option.value as MetaDatumType
            newDatum['value'] = isTags ? EMPTY_TEXT_LIST : EMPTY_TEXT;
          } else if (event.payload.name === "value") {
            // value can be a list or a string
            // if it is a list, it needs to put in a different format
            newDatum["value"] = Array.isArray(event.payload.option)
              ? (Object.fromEntries(
                  event.payload.option.map((entry) => [
                    entry.value,
                    `${entry.value}`,
                  ])
                ) as MetaDatumSet)
              : event.payload.option.value;

            // @ts-expect-error
            const additionalValue: MetaDatumSet = context.datum.additionalValue;
            if (additionalValue) {
              const options = event.payload.option.map(
                (it: Option) => it.value
              );
              const newAdditionalValue = Object.keys(additionalValue)
                .filter((it) => !options.includes(it))
                .reduce((list, item) => ({ ...list, [item]: item }), {});
              newDatum["additionalValue"] = newAdditionalValue;
            }
          } else {

            // any other value will simply be assigned… which should only be 
            // `key` at this point
            newDatum[event.payload.name] = `${event.payload.option.value}`;
          }

          return newDatum;
        }
      })
    },
    guards: {
      intentIsUpdate: (context) => context.intent === "update",
      intentIsMultiUpdate: (context) => context.intent === "multi-update",
      intentIsCreate: (context) => context.intent === "create",

      requestReturnedError: (context, event) => typeof event.data !== "undefined" && event.data.status >= 400
    },
    services: {

    }
  });


export { metaDatumMachine };
