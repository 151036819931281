import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader, ModalTitle
} from '@atlaskit/modal-dialog';
import { AudioPlayerContext, Button, Icon, Time, WaveFormAudio } from '@quantumcast/ui';
import { useSelector } from '@xstate/react';
import { useContext } from 'react';
import { t } from '../../../../global/Localization/translation';
import FileContext from '../../FilesContext';
import { SAMPLES_TO_WIDTH_FACTOR } from './const';
import { CueList } from './CueList';
import { cuePairs, selectors } from './cueMachine';

import { CueStateContext } from './CueStateContext';
import { Cues } from './Player/Cues';
import { DetailSelector } from './Player/DetailSelector';
import { ProgressIndicator } from './Player/ProgressIndicator';
import { getFirstMatchingIndex } from './Player/utils';


const selectFileId = (state) => state.context.fileId
const selectCurrentTime = (state) => state.context.currentTime

const {
  selectId,
  selectArea,
  selectCanSave,
  selectCues,
  selectData,
  selectDetailData,
  selectDuration,
  selectSelectedGroup,
  selectGroupedCues,
  compareCues
} = selectors

type CloseProps = {
  onClose?: () => void
}

const PlayerMetaData = ({ artist, title }) => {
  return <div className='player_meta_data'>
    <div className='player_meta_data__artist'>{artist}</div>
    <div className='player_meta_data__title'>{title}</div>
  </div>
}
const PlayerControls = () => {
  const { playerService, selectors } = useContext(AudioPlayerContext.Context);
  const { cueService } = useContext(CueStateContext)
  const { list } = useContext(FileContext)
  const isPaused = useSelector(playerService, (state) => ['paused'].some(state.matches),);
  const isPlaying = useSelector(playerService, (state) => ['playing'].some(state.matches));
  let currentTime = useSelector(playerService, selectCurrentTime);

  const duration = useSelector(cueService, selectDuration)
  const fileId = useSelector(cueService, selectFileId);
  const playerFileId = useSelector(playerService, selectors.selectId);
  if (fileId !== playerFileId) {
    currentTime = 0;
  }

  const { title, artist, url } = list.find(item => item.id === fileId)

  return <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 2fr 30px', gap: 'calc(var(--spacing) * 4)', alignItems: 'center', marginBottom: 'calc(var(--spacing) * 4)' }}>
    <PlayerMetaData title={title} artist={artist} />
    <div style={{ justifyContent: 'center', display: 'flex' }}>
      {fileId !== playerFileId || !isPlaying ? (
        <Button onClick={() => isPaused ?
          playerService.send({ type: 'continue' }) :
          playerService.send({
            type: 'play',
            data: {
              id: fileId,
              title,
              artist,
              url
            }
          })
        }
          appearance="secondary"
          iconBefore={<Icon.IonIcons5.IoPlay size={30} />}
        />
      ) : (
        <Button
          appearance='secondary'
          isDisabled={fileId !== playerFileId}
          onClick={() => playerService.send({ type: 'pause' })}
          iconBefore={<Icon.IonIcons5.IoPause size={30} />} />
      )
      }
      <Button
        appearance='secondary'
        isDisabled={fileId !== playerFileId}
        onClick={() => playerService.send({ type: 'stop' })}
        iconBefore={<Icon.IonIcons5.IoStop size={30} />} />
    </div>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gridColumn: '-3/-1' }}>
      <Time time={currentTime} />&nbsp;/&nbsp;<Time time={duration} />
    </div>
  </div>
}

const CueModal = ({ onClose = () => { } }: CloseProps) => {
  const { playerService, selectors } = useContext(AudioPlayerContext.Context);
  const { cueService } = useContext(CueStateContext)

  const currentTime = useSelector(playerService, selectCurrentTime)
  const fileId = useSelector(playerService, selectors.selectId)
  
  const cuesFileId = useSelector(cueService, selectId);
  const cues = useSelector(cueService, selectCues, compareCues)
  const data = useSelector(cueService, selectData);
  const detailData = useSelector(cueService, selectDetailData);
  const duration = useSelector(cueService, selectDuration)
  const selectedArea = useSelector(cueService, selectArea);
  const selectedGroup = useSelector(cueService, selectSelectedGroup)
  const groupedCues = useSelector(cueService, selectGroupedCues)
  const canSave = useSelector(cueService, selectCanSave)
  const showEveryNthValue = SAMPLES_TO_WIDTH_FACTOR;
  const width = 920
  const height = 60


  return (

    <Modal
      width={"x-large"}
      onClose={onClose}
    >

      <ModalHeader>
        <ModalTitle>{t("file.cues.title")} "{selectedGroup}"</ModalTitle>
      </ModalHeader>
      <ModalBody>

        <div style={{ display: 'grid', gap: 'calc(var(--spacing) * 2)' }}>
          <div style={{ height: "200px", background: 'rgb(250,251,252)', color: "var(--brand-dark)", borderRadius: '3px' }}>
            {detailData ?
              <WaveFormAudio
                uniqueName="detailData"
                data={detailData}
                width={920}
                height={200}
                events
              >
                <Cues
                  uniqueName='modalDetail'
                  duration={duration}
                  cues={cues}
                  height={200}
                  data={detailData}
                  showEveryNthValue={showEveryNthValue}
                />
                {
                  fileId === cuesFileId && <ProgressIndicator
                    progressX={getFirstMatchingIndex(detailData, currentTime) * showEveryNthValue}
                    height={200}
                  />
                }

              </WaveFormAudio>
              : <div style={{
                width,
                height: '200px'
              }} />}

          </div>
          <div style={{ height: "60px", background: 'rgb(250,251,252)', color: "var(--brand-dark)", borderRadius: '3px' }}>
            {data ?
              <>
                <WaveFormAudio
                  uniqueName="data"
                  data={data}
                  width={width}
                  height={60}
                  events
                >
                  {/* this will show the currently selected area */}
                  <DetailSelector
                    selectedArea={selectedArea}
                    duration={duration}
                    containerWidth={width}
                    containerHeight={60}
                  />
                  <Cues
                    uniqueName='modalOverview'
                    cues={cues}
                    height={height}
                    data={data}
                    duration={duration}
                    showEveryNthValue={showEveryNthValue}
                  />

                  {fileId === cuesFileId && <ProgressIndicator
                    onDrag={(e) => console.log('playback should be paused', e)}
                    onDragEnd={(e) => {
                      // @ts-expect-error
                      services.cueService.send({ type: 'seek', playedSeconds: (duration * e.dx / width) / 1000 })
                      return console.log('new position', e);
                    }}
                    onDragMove={(e) => {

                      console.log('moving', e)
                      // @ts-expect-error
                      services.cueService.send({ type: 'seek', playedSeconds: (duration * e.dx / width) / 1000 })
                    }}
                    progressX={getFirstMatchingIndex(data, currentTime) * showEveryNthValue}
                    height={60}
                  />}
                </WaveFormAudio>
              </>
              :
              <div style={{
                width,
                height
              }} />
            }

            {/* {data?.findIndex(point => point.timestamp >= currentTime)} */}
          </div>
          <PlayerControls></PlayerControls>
          <CueList fileId={fileId} uniqueName="modal" />
        </div>

        <div style={{ marginTop: 'calc(var(--spacing) * 2)' }}>
          <DropdownMenu trigger="add cue point" appearance='tall' >
            <DropdownItemGroup>
              {cuePairs.map(pair => {
                // @ts-expect-error
                const typeAlreadyExists = groupedCues[selectedGroup].map(cue => pair.includes(cue.type)).some(Boolean)

                if (pair.length === 2) {
                  return <DropdownItem isDisabled={typeAlreadyExists} onClick={() => cueService.send({ type: 'addCuePair', pair })}>
                    add {t(`file.cues.types.${pair[0]}`)}/{t(`file.cues.types.${pair[1]}`)}
                  </DropdownItem>
                }
                return <DropdownItem isDisabled={typeAlreadyExists} onClick={() => cueService.send({ type: 'addCuePair', pair })}>
                  add {t(`file.cues.types.${pair[0]}`)}
                </DropdownItem>

              })}
            </DropdownItemGroup>
          </DropdownMenu>
        </div>



      </ModalBody>
      <ModalFooter>
        <Button
          appearance="secondary"
          onClick={() => {
            cueService.send('cancel')
            onClose()
          }}
        >
          {t('action.cancel')}
        </Button>
        <Button
          appearance="primary"
          isDisabled={!canSave}
          onClick={() => cueService.send('save')}
        >
          {t('action.save')}
        </Button>
      </ModalFooter>
    </Modal>

  )


}

export {
  CueModal
};