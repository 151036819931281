import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    ModalTransition
} from '@atlaskit/modal-dialog';
import { Button, Icon } from "@quantumcast/ui";
import { useMachine } from '@xstate/react';
import React from "react";
import { t } from "../../../../../global/Localization/translation";
import FileContext from "../../../FilesContext";
import MetaDatum from "../MetaDatum/MetaDatum";
import "./AddMetaData.scss";
import { addMetaDataMachine } from "./metaDataMachine";
import { RawFileMetaDatum } from '../../../types';

export interface Props {
  fileId?: string;
  saveToMemory?: (datum: Partial<RawFileMetaDatum>) => void;
  disabledKeys?: string[];
}

const AddMetaData = (props: Props) => {
  const files = React.useContext(FileContext);

  const [state, send] = useMachine(addMetaDataMachine, {
    context: {
      files,
      fileId: props.fileId,
    },
    services: {
      addMetaDatum: async (context, event) => {
        if (Boolean(props.saveToMemory)) {
          props.saveToMemory(context.datum);
        } else {
          return await context.files.onAddMetaDatum(
            context.fileId,
            context.datum
          )
        };
      },
    },
  })

  return (
    <>
      <Button
        appearance="link"
        onClick={() => send('openModal')}
        className="add-meta-data"
      >
        <Icon.IonIcons5.IoAdd size={12} />
        {t("file.metaData.add")}
      </Button>
      <ModalTransition>
        {state.matches('modalOpened') && (
          <Modal
            onClose={() => send('close')}>

            <ModalHeader>
              <ModalTitle>{t("file.metaData.add")}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <MetaDatum
                onChange={(data) => send({ type: 'validateMetaDatum', payload: data })}
                datum={{
                  type: 'TEXT',
                }}
                intent="create"
                fileId={state.context.fileId}
                disabledKeys={props.disabledKeys}
              />
            </ModalBody>
            <ModalFooter>
              <Button appearance="secondary" onClick={() => send('close')} type="reset">
                {t('action.cancel')}
              </Button>
              <Button
                type="submit"
                appearance="primary"
                onClick={() => send('saveMetaData')}
                isLoading={state.matches('modalOpened.saving')}
                isDisabled={!state.can('saveMetaData')}
              >
                {t('action.save')}
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default AddMetaData;
