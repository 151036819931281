import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { Banner, Button, Icon } from "@quantumcast/ui";
import { useMachine } from "@xstate/react";
import React from "react";
import { t } from "../../../../../global/Localization/translation";
import ConfigContext from "../../../../Config/ConfigContext";
import { Playlist } from "../../../domain";
import PlaylistsContext from "../../../PlaylistsContext";
import { PlaylistRuleAdditive } from "../../../types";
import PlaylistAdditive from "../PlaylistAdditive/PlaylistAdditive";
import "./AddPlaylistAdditive.scss";
import { addPlaylistAdditiveMachine } from "./addPlaylistAdditiveMachine";

type Props = {
  playlist: Playlist;
};

const AddPlaylistAdditive = (props: Props) => {
  const config = React.useContext(ConfigContext);
  const playlists = React.useContext(PlaylistsContext);
  const [state, sendEvent] = useMachine(addPlaylistAdditiveMachine, {
    context: {
      additive: {},
    },
    services: {
      addPlaylistAdditive: (context) => {
        const newAdditives = [
          ...props.playlist.rules.additives,
          context.additive as PlaylistRuleAdditive,
        ];
        return playlists.onSave(props.playlist.id, {
          rules: {
            ...props.playlist.rules,
            additives: newAdditives,
          },
        });
      },
    },
  });

  const onChange = (item: Partial<PlaylistRuleAdditive>) => {
    sendEvent({ type: "CHANGE", additive: item });
  };

  return (
    <div className="add-playlist-additive">
      <Button
        onClick={() => sendEvent("OPEN")}
        appearance="link"
        iconBefore={
          <Icon.IonIcons5.IoAdd title={t("file.cues.delete")} size={15} />
        }
      >
        {t("playlist.settings.rules.additive.add")}
      </Button>
      <ModalTransition>
        {state.matches("MODAL_OPEN") && (
          <Modal onClose={() => sendEvent("CLOSE")}>
            <ModalHeader>
              <ModalTitle>
                {t("playlist.settings.rules.additive.add")}
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              {state.context.error && (
                <div className="delete-playlist__error">
                  <Banner appearance="error">{t(state.context.error)}</Banner>
                </div>
              )}
              <PlaylistAdditive
                type="add"
                additive={state.context.additive}
                metaDataKeys={config.metaDataKeys}
                onSearchMetaDataValues={config.onSearchMetaDataValues}
                onChange={onChange}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                appearance="secondary"
                onClick={() => sendEvent("CLOSE")}
                type="reset"
              >
                {t("action.cancel")}
              </Button>
              <Button
                type="submit"
                appearance="primary"
                onClick={() => sendEvent({ type: "SUBMIT" })}
                isLoading={state.matches("MODAL_OPEN.PROCESSING")}
                isDisabled={!state.can("SUBMIT")}
              >
                {t("action.save")}
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default AddPlaylistAdditive;
