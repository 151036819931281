import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition
} from "@atlaskit/modal-dialog";
import { Banner, Button } from "@quantumcast/ui";
import { useActor } from "@xstate/react";
import { useContext } from "react";
import { t } from "../../../../../global/Localization/translation";
import "./DeletePlaylist.scss";
import { DeletePlaylistContext } from "./DeletePlaylistContext";

const DeletePlaylist = () => {
  const { deletePlaylistsService } = useContext(DeletePlaylistContext);
  const { send: sendEvent } = deletePlaylistsService;
  const [state] = useActor(deletePlaylistsService);

  return (
    <div className="delete-playlist">
      <ModalTransition>
        {state.matches("MODAL_OPEN") && (
          <Modal onClose={() => sendEvent("CLOSE")}>
            <ModalHeader>
              <ModalTitle>{t("playlist.delete.title")}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              {state.context.error && (
                <div className="delete-playlist__error">
                  <Banner appearance="error">{t(state.context.error)}</Banner>
                </div>
              )}
              {t("playlist.delete.description", {
                name: state.context.playlist.name,
              })}
            </ModalBody>
            <ModalFooter>
              <Button
                appearance="secondary"
                onClick={() => sendEvent("CLOSE")}
                type="reset"
              >
                {t("action.cancel")}
              </Button>
              <Button
                type="submit"
                appearance="danger"
                onClick={() => sendEvent({ type: "DELETE" })}
                isLoading={state.matches("MODAL_OPEN.PROCESSING")}
              >
                {t("action.delete")}
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default DeletePlaylist;
