import { HttpError } from "@klw/fetch/lib/types";
import { assign, createMachine } from "xstate";
import { z } from "zod";

export type Context = {
  error?: string;
  name: string;
};

export type Event =
  | { type: "OPEN" }
  | { type: "CLOSE" }
  | { type: "CHANGE"; name: string }
  | { type: "SUBMIT" };

export type Services = {
  addPlaylist: { data: HttpError | void };
};

export const addPlaylistsMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEMIQAoBtkE9MEtYAXAWWQGMALfAOzADoBJAEQBkBRAYgHl12A5ANoAGALqJQABwD2sfEXzSaEkAA9EAVgBMAGhA5EADgCM9ACyGNANgDsGk8Zu37AXxd7UGbHkKkK1OnoSbmYAQVYAfV4BJjYuAGUAVQAhEkYAFRFxJBAZOQUlFXUELWMtehsbAE4zDQ0zGwBmW0c9AwRGp3oq4zMreptDKq1hV3cQTyxcAmIyKloGYLDI6P5Yjk4AYQAJUP4AcXYslTz5RWUc4sMbNsQtG1MtYcNaq2MqntsrNw80KZ9Zv4FkEQuEonw1iwNptWNx4kcxCdZGdCpdEP1hPRhFZnoZGmZjBpOlVbggzFVDPQtM1etdLPYbGZGj8Jn9vDM-PNAkswat6OgAErcTbseLxRgHTgQJQMWgAN2kAGsGJN2b45gFFqCVhD+UKRWKJfsEPLpORkAUaFljjlTpaiuiNJjsbj8YTiaSnjYKlo+hpCRZGhonCzVdN1UDudrwTFBcLReLJWAAE7J6TJ+iSbBEABm6YAtvQwwDOZqQcsY2s4wbE8bTebLdbEbbkfa0SVGqYHvYmWYzE9rC9PSZ6I0XsIhrV-W9jG5xjRpBA4CpixyNQskflzg6EABaEn6RC777jVcRrkMKHsTcoi6gK6NTEfYZWTuGa6NfGkxpacpWKwvGYwilEMFJmKGbLhoCF7lryEI3m296II0B7tMYeL0IYwgPDiWEmOS6EQV4UGlsCPI6jEV4Idu7bGKM9AaFUlRjq+LzGJ+uiHh0jLdL0-QNEMIxjL8xEluuUYVny1YJka1GokhZLGI8DzvNcWjBs0nHtAO3QAb61RBtUwjgXOQA */
  createMachine(
    {
      id: "addPlaylistMachine",
      schema: {
        context: {} as Context,
        events: {} as Event,
        services: {} as Services,
      },
      tsTypes: {} as import("./addPlaylistsMachine.typegen").Typegen0,
      initial: "IDLE",
      states: {
        IDLE: {
          on: {
            OPEN: {
              target: "MODAL_OPEN",
            },
          },
        },
        MODAL_OPEN: {
          initial: "IDLE",
          states: {
            IDLE: {
              on: {
                SUBMIT: {
                  target: "PROCESSING",
                  cond: "isValid",
                },
                CHANGE: {
                  actions: "change",
                },
                CLOSE: {
                  target: "#addPlaylistMachine.IDLE",
                },
              },
            },
            PROCESSING: {
              invoke: {
                src: "addPlaylist",
                onDone: [
                  {
                    target: "#addPlaylistMachine.IDLE",
                    actions: "reset",
                  },
                ],
                onError: [
                  {
                    target: "IDLE",
                    actions: "assignError",
                  },
                ],
              },
            },
          },
        },
      },
    },
    {
      actions: {
        change: assign((_, event) => ({
          name: event.name,
        })),
        reset: assign({ name: "", error: undefined }),
        assignError: assign((_, __) => ({
          error: "playlist.add.error",
        })),
      },
      guards: {
        isValid: (context, event) => {
          const schema = z.object({
            name: z.string().min(3),
          });

          const result = schema.safeParse(context);
          return result.success;
        },
      },
    }
  );
