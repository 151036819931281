import { WaveFormDatum } from "@quantumcast/ui/src/atoms/WaveFormAudio/WaveFormAudio"

const clamp = (value: number, maxValue: number) => {
    return (value < 0) ? 0 : (value > maxValue) ? maxValue : value
}

const getFirstMatchingIndex = (data: WaveFormDatum[], needle: number): number => {
    return data?.findIndex(point => point.timestamp >= needle)
}
const isWithinBounds = (data: WaveFormDatum[], needle: number) => {
    return getFirstMatchingIndex(data, needle) !== -1
}

const getWidthForDuration = ({
    duration = 10_000,
    availableDuration,
    availableWidth }: {
        /**
         * duration in miliseconds
         */
        duration?: number;
        /**
         * total duration in miliseconds
         */
        availableDuration: number;
        /**
         * full width available to render in px
         */
        availableWidth: number
    }) => availableWidth / availableDuration * duration

const pixelOffsetInSeconds = ({
    offset = 0,
    availableDuration,
    availableWidth
}: {
    /**
     * offset in px
     */
    offset?: number;
    /**
     * total duration in miliseconds
     */
    availableDuration: number;
    /**
     * full width available to render in px
     */
    availableWidth: number
}) => offset / availableWidth * availableDuration

export { clamp, getFirstMatchingIndex, isWithinBounds, getWidthForDuration, pixelOffsetInSeconds }