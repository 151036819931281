import * as React from "react";
import { ConfigContextProvider } from "../../features/Config/ConfigContext";
import { FileContextProvider } from "../../features/Files/FilesContext";
import { AuthContextProvider } from "../Auth/AuthContext";
import { AudioPlayerContext } from "@quantumcast/ui";
import { PlaylistsContextProvider } from "../../features/Playlists/PlaylistsContext";
import { DeletePlaylistProvider } from "../../features/Playlists/PlaylistList/PlaylistListItem/DeletePlaylist/DeletePlaylistContext";

interface Props {
  children?: React.ReactNode;
}

const Contexts = (props: Props) => {
  return (
    <AudioPlayerContext.Provider>
      <ConfigContextProvider>
        <AuthContextProvider>
          <FileContextProvider>
            <PlaylistsContextProvider>
              <DeletePlaylistProvider>{props.children}</DeletePlaylistProvider>
            </PlaylistsContextProvider>
          </FileContextProvider>
        </AuthContextProvider>
      </ConfigContextProvider>
    </AudioPlayerContext.Provider>
  );
};

export default Contexts;
