import {
  RawCue,
  RawFile,
  RawFileCoreDatum,
  RawFileMetaDatum,
} from "./types";

export class File {
  id: string;
  archiveNo: string;
  title: string;
  artist: string;
  url: string;
  waveformDataUrl: string;
  duration: number;
  cues: RawCue[];
  coreData: RawFileCoreDatum[];
  metaData: RawFileMetaDatum[];

  constructor(raw: RawFile) {
    this.id = raw.id;
    this.archiveNo = raw.archiveNo || "";
    this.title = raw.title || "";
    this.artist = raw.artist || "";
    this.url = raw.url;
    this.waveformDataUrl = raw.waveformDataUrl;
    this.duration = raw.duration;
    this.cues = raw.cues;
    this.coreData = raw.coreData;
    this.metaData = raw.metaData;
  }
}