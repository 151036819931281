import { Icon, MultiSelect, Option, Select } from "@quantumcast/ui";
import React from "react";
import { t } from "../../../../../global/Localization/translation";
import { PlaylistRuleAdditive } from "../../../types";

import "./PlaylistAdditive.scss";

const MODIFIERS: Option[] = [
  {
    label: t("playlist.settings.rules.additive.contains"),
    value: "CONTAINS",
  },
  {
    label: t("playlist.settings.rules.additive.does_not_contain"),
    value: "DOES_NOT_CONTAIN",
  },
];

type PropsAdd = {
  type: "add";
  additive: Partial<PlaylistRuleAdditive>;
  onChange: (item: Partial<PlaylistRuleAdditive>) => void;
};

type PropsEdit = {
  type: "edit";
  index: number;
  additive: PlaylistRuleAdditive;
  onChange: (index: number, item: PlaylistRuleAdditive) => void;
  onDelete: (index: number) => void;
};

type Props = (PropsAdd | PropsEdit) & {
  metaDataKeys: Option[];
  onSearchMetaDataValues: (search: string) => Promise<string[]>;
};

const PlaylistAdditive = (props: Props) => {
  const toOption = (it: string) => ({ label: it, value: it });

  const metaDatumKey: Option = props.additive?.metaDatumKey
    ? toOption(props.additive.metaDatumKey)
    : undefined;
  const modifier: Option = MODIFIERS.find(
    (it) => it.value === props.additive.modifier
  );
  const metaDatumValues: Option[] = props.additive.metaDatumValues
    ? props.additive.metaDatumValues.map(toOption)
    : [];
  const [options, setOptions] = React.useState([]);

  const onChangeKey = (_: string, option: Option) => {
    if (props.type === "edit") {
      props.onChange(props.index, {
        ...props.additive,
        metaDatumKey: option.value,
      });
    }
    if (props.type === "add") {
      props.onChange({
        ...props.additive,
        metaDatumKey: option.value,
      });
    }
  };

  const onChangeMondifier = (_: string, option: Option) => {
    // TODO: Improve typing
    type ModifierType = "CONTAINS" | "DOES_NOT_CONTAIN";
    if (props.type === "edit") {
      props.onChange(props.index, {
        ...props.additive,
        modifier: option.value as ModifierType,
      });
    }
    if (props.type === "add") {
      props.onChange({
        ...props.additive,
        modifier: option.value as ModifierType,
      });
    }
  };

  const onChangeValues = (_: string, options: Option[]) => {
    if (props.type === "edit") {
      props.onChange(props.index, {
        ...props.additive,
        metaDatumValues: options.map((it) => it.value),
      });
    }
    if (props.type === "add") {
      props.onChange({
        ...props.additive,
        metaDatumValues: options.map((it) => it.value),
      });
    }
  };

  const onSearchValues = async (name: string, value: string) => {
    const resp = await props.onSearchMetaDataValues(value);
    setOptions(resp.map(toOption));
  };

  const onDelete = () => {
    props.type === "edit" && props.onDelete(props.index);
  };

  const classNames = ["playlist-additive", `playlist-additive--${props.type}`];

  return (
    <div className={classNames.join(" ")}>
      <Select
        name="metaDatumKey"
        placeholder={t("playlist.settings.rules.additive.metaDatum")}
        label={
          <div className="playlist-additive__label">
            {t("playlist.settings.rules.additive.metaDatum")}
          </div>
        }
        options={props.metaDataKeys}
        value={metaDatumKey ?? undefined}
        onChange={onChangeKey}
      />
      <Select
        name="modifier"
        placeholder={t("playlist.settings.rules.additive.modifier")}
        label={
          <div className="playlist-additive__label">
            {t("playlist.settings.rules.additive.modifier")}
          </div>
        }
        options={MODIFIERS}
        value={modifier ?? undefined}
        onChange={onChangeMondifier}
      />
      {props.type === "edit" && (
        <div className="playlist-additive__delete" onClick={onDelete}>
          <Icon.IonIcons5.IoTrashBinSharp
            title={t("playlist.settings.rules.additive.delete")}
          />
        </div>
      )}
      <MultiSelect
        name="metaDatumValues"
        placeholder={t("playlist.settings.rules.additive.values")}
        options={options}
        isCreateable={false}
        onChangeInput={onSearchValues}
        value={metaDatumValues}
        onChange={onChangeValues}
      />
    </div>
  );
};

export default PlaylistAdditive;
