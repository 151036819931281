import {
  Banner,
  Button,
  Icon,
  Modal,
  Time,
  useAudioPlayer,
} from "@quantumcast/ui";
import { useSelector } from "@xstate/react";
import React from "react";
import { t } from "../../../../global/Localization/translation";
import { useToggle } from "../../../../utils/useToggle";
import type { File } from "../../domain";
import FileContext from "../../FilesContext";
import { FileSelectionAction } from "../../reducers";
import CoreData from "../CoreData/CoreData";
import Cues from "../Cues/Cues";
import MetaData from "../MetaData/MetaData";
import "./FileListItem.scss";
import FileSelection from "./FileSelection/FileSelection";

interface Props {
  file: File;
  onUpdateSelection: (action: FileSelectionAction) => void;
}

const FileListItem = (props: Props) => {
  const { playerService } = useAudioPlayer();
  const isPlaying = useSelector(
    playerService,
    (state) => state.matches("playing") && state.context.url === props.file.url
  );

  const files = React.useContext(FileContext);
  const fileToggle = useToggle();
  const deleteToggle = useToggle();
  const deleteLoading = useToggle();
  const isOpen = fileToggle.isTrue;
  const isSelected =
    files.selection.selected.findIndex((it) => it === props.file.id) >= 0;

  const classNames = ["file"];
  if (isOpen) {
    classNames.push("file--is-open");
  }
  if (isSelected) {
    classNames.push("file--is-selected");
  }

  const onPlay = (e: React.MouseEvent<SVGElement>) => {
    e.stopPropagation();
    playerService.send({
      type: "play",
      data: {
        id: props.file.id,
        title: props.file.title || props.file.archiveNo || props.file.id,
        artist: props.file.artist,
        url: props.file.url,
      },
    });
  };

  const onStop = (e: React.MouseEvent<SVGElement>) => {
    e.stopPropagation();
    playerService.send({ type: "stop" });
  };

  const onDelete = async () => {
    deleteLoading.onToggle();
    await files.onDelete(props.file);
    deleteLoading.onToggle();
  };

  const onSelect = () => {
    props.onUpdateSelection({
      type: isSelected ? "remove" : "add",
      file: props.file.id,
    });
  };

  return (
    <div className={classNames.join(" ")}>
      <div
        className="file__header"
        onClick={fileToggle.onToggle}
        title={t("file.showDetails")}
      >
        <FileSelection
          isSelected={isSelected}
          isOpen={isOpen}
          onClick={onSelect}
        />
        <div className="file__data">
          <div className="file__title">
            {props.file.title || props.file.archiveNo || props.file.id}
          </div>
          <div className="file__artist">{props.file.artist}</div>
        </div>
        <div className="file__player">
          <div className="file__time">
            <Time time={props.file.duration} />
          </div>
          <div className="file__play">
            {isPlaying ? (
              <Icon.IonIcons5.IoStop
                size={25}
                onClick={onStop}
                title={t("file.player.stop")}
              />
            ) : (
              <Icon.IonIcons5.IoPlay
                size={25}
                onClick={onPlay}
                title={t("file.player.start")}
              />
            )}
          </div>
        </div>
      </div>
      {fileToggle.isTrue && (
        <div className="file__body">
          <div className="file__base">
            <div className="file__core-data">
              <CoreData data={props.file.coreData} />
            </div>
            <div className="file__delete">
              <Button appearance="danger" onClick={deleteToggle.onToggle}>
                {t("file.delete")}
              </Button>
              <Modal
                isOpen={deleteToggle.isTrue}
                onClose={deleteToggle.onToggle}
                title={t("file.delete")}
                actions={
                  <>
                    <Button appearance="link" onClick={deleteToggle.onToggle}>
                      {t("action.cancel")}
                    </Button>
                    <Button
                      appearance="danger"
                      onClick={onDelete}
                      isLoading={deleteLoading.isTrue}
                    >
                      {t("file.delete")}
                    </Button>
                  </>
                }
              >
                {t("file.deleteInfo", {
                  artist: props.file.artist,
                  title: props.file.title,
                })}
              </Modal>
            </div>
          </div>
          <div className="file__cues">
            {Boolean(props.file.cues) && props.file.cues.length !== 0 ? (
              <Cues
                fileId={props.file.id}
                cues={props.file.cues}
                fileUrl={props.file.url}
                duration={props.file.duration}
                waveformDataUrl={props.file.waveformDataUrl}
              />
            ) : (
              <Banner appearance="error">{t("file.cues.noCuesError")}</Banner>
            )}
          </div>

          <div className="file__metaData">
            <MetaData fileId={props.file.id} metaData={props.file.metaData} />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileListItem;
