import { assign, createMachine } from "xstate";

const addCueKeyModalMachine = createMachine({
    id: 'addCueKeyModalMachine',
    initial: 'closed',
    tsTypes: {} as import("./addCueKeyModalMachine.typegen").Typegen0,
    schema: {
        services: {} as {
            'addCueGroup': {
                data: void
            }
        },
        context: {} as {
            value?: { value: string, label: string },
            notAllowedCueKeys: string[]
        },
        events: {} as {
            type: 'updateValue',
            value: { value: string, label: string }
        } | {
            type: 'open'
        } | {
            type: 'close'
        } | {
            type: 'save'
        } | {
            type: 'updateNotAllowedCueKeys',
            notAllowedCueKeys: string[]
        }
    },
    context: {
        value: undefined,
        notAllowedCueKeys: []
    },
    states: {
        closed: {
            on: {
                open: {
                    target: 'open'
                }
            }
        },
        open: {
            on: {
                updateNotAllowedCueKeys: {
                    actions: ['storeNotAllowedCueKeys']
                },
                updateValue: {
                    actions: ['storeValue']
                },
                close: {
                    target: 'closed'
                },
                save: {
                    target: 'saving',
                    cond: 'canSave',
                }
            }
        },
        saving: {
            invoke: {
                src: "addCueGroup",
                onDone: {
                    target: "closed",
                    actions: ['clearValue']
                },
                onError: {
                    target: 'open',
                    actions: ['showError']
                }
            },
        }
    }
}, {
    guards: {
        canSave: (context, event) => Boolean(context.value) && !context.notAllowedCueKeys.includes(context.value.value)
    },
    actions: {
        clearValue: assign((context, event) => ({
            value: undefined
        })),
        storeValue: assign((context, event) => ({
            value: event.value
        })),
        storeNotAllowedCueKeys: assign((context, event) => ({
            notAllowedCueKeys: event.notAllowedCueKeys
        }))
    },
    services: {
        addCueGroup: async (context, event) => {}
    }
})

export { addCueKeyModalMachine }