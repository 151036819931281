import { MouseEvent } from "react";
import "./FileSelection.scss";

interface Props {
  isSelected: boolean;
  isOpen: boolean;
  onClick: () => void;
}

const FileSelection = (props: Props) => {
  const classNames = ["file-selection"];

  if (props.isSelected) {
    classNames.push("file-selection--is-selected");
  }
  if (props.isOpen) {
    classNames.push("file-selection--is-open");
  }

  const onClick = (event: MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    props.onClick();
  };

  return (
    <div
      className={classNames.join(" ")}
      // @ts-ignore
      onClick={onClick}
    />
  );
};

export default FileSelection;
