import { FileSelection } from "./types";

export type FileSelectionAction =
  | {
      type: "add" | "remove";
      file: string;
    }
  | { type: "reset" }
  | { type: "all"; ids: string[] };

export const fileSelectionReducer = (
  state: FileSelection,
  action: FileSelectionAction
): FileSelection => {
  let newSelection = [...state.selected];
  switch (action.type) {
    case "all":
      return { selected: action.ids };
    case "add":
      return { selected: [...newSelection, action.file] };
    case "remove":
      newSelection = newSelection.filter((it) => it !== action.file);
      return { selected: newSelection };
    case "reset":
      return { selected: [] };
    default:
      return state;
  }
};
