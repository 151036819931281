import * as React from "react";
import { useJwt } from "@klw/jwt";
import { useLocalStorage } from "@klw/localstorage";
import { get, post } from "@klw/fetch";
import { HTTP_ERROR_UNKNOWN } from "../../utils/rest";
import STORAGE_NAMES from "../../utils/storageNames";
import {
  OnCheckPermission,
  OnCheckToken,
  OnForgotPassword,
  OnLogin,
  OnLogout,
  OnResetPassword,
  Permission,
} from "./types";
import { useHistory } from "react-router";
import { useInterval } from "../../utils/useInterval";
import { DEFAULT_LIBRARY } from "./const";

interface IAuthContext {
  isAuthenticated: boolean;
  token?: string;
  libraries: string[];
  onForgotPassword: (email: string) => Promise<OnForgotPassword>;
  onCheckToken: (token: string) => Promise<OnCheckToken>;
  onCheckPermission: (type: string) => OnCheckPermission;
  onLogin: (email: string, password: string) => Promise<OnLogin>;
  onLogout: () => Promise<OnLogout>;
  onResetPassword: (
    token: string,
    password: string
  ) => Promise<OnResetPassword>;
}

interface IAuthContextProvider {
  children?: React.ReactNode;
}

const defaultContext = {
  isAuthenticated: false,
};

const AuthContext = React.createContext<IAuthContext>(
  defaultContext as IAuthContext
);

export const AuthContextProvider = (props: IAuthContextProvider) => {
  const history = useHistory();
  const jwt = useJwt();
  const storage = useLocalStorage();
  const [isAuthenticated, setIsAuthenticated] = React.useState(
    defaultContext.isAuthenticated
  );
  const [permissions, setPermissions] = React.useState<Permission[]>([]);
  const [libraries, setLibraries] = React.useState<string[]>([]);

  const onCheckPermission = (type: string) => {
    return Boolean(permissions.find((permission) => permission === type));
  };

  const onCheckToken = async (token: string) => {
    const resp = await get(`/api/users/refresh`);
    if (resp.ok && resp.body?.token) {
      onSuccessfullLogin(resp.body.token);
      refresh.onStart();
      return true;
    } else {
      setIsAuthenticated(false);
      storage.remove(STORAGE_NAMES.TOKEN);
      return false;
    }
  };

  const onForgotPassword = async (email: string): Promise<OnForgotPassword> => {
    const resp = await post(`/api/users/forgot-password`, { email });
    if (resp.ok) {
      return Promise.resolve();
    } else {
      return Promise.reject({
        ok: false,
        type: resp.error?.type || HTTP_ERROR_UNKNOWN,
      });
    }
  };

  const onLogin = async (email: string, password: string): Promise<OnLogin> => {
    const resp = await post(`/api/users/login`, { email, password });
    if (resp.ok && resp.body?.token) {
      refresh.onStart();
      onSuccessfullLogin(resp.body.token);
      return Promise.resolve();
    } else {
      return Promise.reject({
        ok: false,
        type: resp.error?.type || HTTP_ERROR_UNKNOWN,
      });
    }
  };

  const onLogout = async (): Promise<OnLogin> => {
    await get(`/api/users/logout`);
    storage.remove(STORAGE_NAMES.TOKEN);
    refresh.onStop();
    setIsAuthenticated(false);
    setPermissions([]);
    history.push("/");
    return Promise.resolve();
  };

  const onRefreshToken = async () => {
    const resp = await get(`/api/users/refresh`);
    if (resp.ok && resp.body?.token) {
      onSuccessfullLogin(resp.body.token);
      return true;
    } else {
      onLogout();
      return false;
    }
  };

  const onResetPassword = async (
    token: string,
    newPassword: string
  ): Promise<OnLogin> => {
    const resp = await post(`/api/users/reset-password`, {
      token,
      newPassword,
    });
    if (resp.ok && resp.body?.token) {
      onSuccessfullLogin(resp.body.token);
      history.push("/");
      return Promise.resolve();
    } else {
      return Promise.reject({
        ok: false,
        type: resp.error?.type || HTTP_ERROR_UNKNOWN,
      });
    }
  };

  // private
  const onSuccessfullLogin = (token: string) => {
    storage.set(STORAGE_NAMES.TOKEN, token);
    const data = jwt.decode(token);
    if (data) {
      // @ts-ignore
      data.hasOwnProperty("permissions") && setPermissions(data.permissions);
      data.hasOwnProperty("owner")
        ? // @ts-ignore
          setLibraries(data.owner)
        : setLibraries([DEFAULT_LIBRARY]);
    }
    setIsAuthenticated(true);
  };

  const refresh = useInterval(onRefreshToken, 60 * 5);

  const state: IAuthContext = {
    isAuthenticated,
    libraries,
    onCheckPermission,
    onCheckToken,
    onForgotPassword,
    onLogin,
    onLogout,
    onResetPassword,
  };

  return (
    <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>
  );
};

export default AuthContext;
