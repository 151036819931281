import * as React from "react";
import Page from "../../components/layouts/Page/Page";
import FileContext from "./FilesContext";
import "./Files.scss";
import {
  EmptyState,
  Icon,
  Input,
  Label,
  Option,
  Select,
  VirtualizedList,
} from "@quantumcast/ui";
import { t } from "../../global/Localization/translation";
import AddFiles from "./AddFiles/AddFiles";
import SearchHelp from "../Help/SearchHelp/SearchHelp";
import { ORDER_BY } from "./const";
import AuthContext from "../../global/Auth/AuthContext";
import FileListItem from "./FileList/FileListItem/FileListItem";
import FileListMeta from "./FileList/FileListMeta";
import FileEditor from "./FileList/FileEditor/FileEditor";
import FileSelection from "./FileList/FileListItem/FileSelection/FileSelection";
import ConfigContext from "../Config/ConfigContext";

const Files = () => {
  const auth = React.useContext(AuthContext);
  const config = React.useContext(ConfigContext);
  const files = React.useContext(FileContext);
  const [modal, setModal] = React.useState<"search" | null>(null);
  const isAllSelected = files.list.length === files.selection.selected.length;

  const onChangeLibrary = (name: string, option: Option) => {
    files.onChangeLibrary(option.value);
  };

  const onSearch = (e: React.FormEvent<HTMLInputElement>) => {
    files.onChangeSearch(e.currentTarget.value);
  };

  const onChangeOrder = (name: string, option: Option) => {
    files.onChangeOrder(option);
  };

  const onToggleSearchHelp = () => {
    setModal(modal === "search" ? null : "search");
  };

  const onToggleSelectAll = () => {
    if (isAllSelected) {
      files.onUpdateSelection({
        type: "reset",
      });
    } else {
      files.onUpdateSelection({
        type: "all",
        ids: files.list.map((it) => it.id),
      });
    }
  };

  const isSingleLibrary = auth.libraries.length <= 1;

  const items = files.list.map((file) => ({
    key: file.id,
    node: (
      <FileListItem
        key={file.id}
        file={file}
        onUpdateSelection={files.onUpdateSelection}
      />
    ),
  }));

  return (
    <Page width="narrow">
      <div className="files">
        <div className="files__header">
          <div className="files__libraries">
            <Select
              label={t("files.library.label")}
              name="library"
              value={{ label: config.library, value: config.library }}
              options={auth.libraries.map((it) => ({
                label: it,
                value: it,
              }))}
              isDisabled={isSingleLibrary}
              onChange={onChangeLibrary}
            />
          </div>
          <div className="files__search">
            <Input
              name="search"
              value={files.search || ""}
              placeholder={t("files.search.placeholder")}
              label={
                <Label
                  label={t("files.search.label")}
                  icon={
                    <Icon.IonIcons5.IoInformationCircleSharp
                      size={15}
                      onClick={onToggleSearchHelp}
                      cursor="help"
                    />
                  }
                />
              }
              isClearable={true}
              onChange={onSearch}
            />
            <SearchHelp
              isOpen={modal === "search"}
              onToggle={onToggleSearchHelp}
            />
          </div>
          <div className="files__order">
            <Select
              label={t("files.order.label")}
              name="order"
              value={files.order}
              options={ORDER_BY}
              onChange={onChangeOrder}
            />
          </div>
          <div className="files__add">
            <AddFiles />
          </div>
        </div>
        {!files.isLoading && (
          <div className="files__sticky-header">
            <div className="files__select-all">
              <div className="files__select-all-toggle">
                <FileSelection
                  isSelected={isAllSelected}
                  isOpen={false}
                  onClick={onToggleSelectAll}
                />
              </div>
              <div className="files__select-all-text">
                <FileListMeta
                  total={files.total}
                  length={files.length}
                  selection={files.selection.selected.length}
                />
              </div>
            </div>
            <FileEditor />
          </div>
        )}
        <VirtualizedList
          isLoading={files.isLoading}
          items={items}
          itemDefaultHeight={60}
          emptyState={<EmptyState text={t("files.notFound")} />}
        />
        {!files.isLoading && !files.search && (
          <div className="files__footer">{t("files.footer")}</div>
        )}
      </div>
    </Page>
  );
};

export default Files;
