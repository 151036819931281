import { Drag } from "@visx/drag";
import { Bar } from "@visx/shape";
import { useContext } from "react";
import { CueStateContext } from "../CueStateContext";
import { clamp, pixelOffsetInSeconds } from "./utils";

const DetailSelector = ({ selectedArea, duration, containerWidth, containerHeight }) => {
    const services = useContext(CueStateContext)
    const { send } = services.cueService
    
    const width = containerWidth;
    const height = containerHeight;

    return <Drag
        // key={`drag-${d.id}`}
        width={width || 200}
        height={height || 60}
        x={selectedArea.x}
        y={selectedArea.y}
        restrict={{
            // xMin: 0,
            // xMax: width - selectedArea.width,
            yMax: 0,
            yMin: 0
        }}
    >
        {({ dragStart, dragEnd, dragMove, isDragging, x, y, dx, dy }) => {
            const augmentedDragEnd = (e) => {
                const offset = clamp(dx, width - selectedArea.width)
                const offsetInMs = Math.floor(pixelOffsetInSeconds({
                    offset,
                    availableWidth: width,
                    availableDuration: duration
                }));
                send({ type: 'dragZoom', offset: offsetInMs });
                dragEnd(e);
            };
            return (
                <Bar
                    x={clamp(dx, width - selectedArea.width)}
                    y={dy}
                    onMouseDown={dragStart}
                    onTouchStart={dragStart}
                    onMouseMove={isDragging ? dragMove : undefined}
                    onTouchMove={isDragging ? dragMove : undefined}
                    onMouseUp={isDragging ? augmentedDragEnd : undefined}
                    onTouchEnd={isDragging ? augmentedDragEnd : undefined}
                    width={selectedArea.width}
                    height={height}
                    // fill="transparent"
                    fillOpacity="0.2"
                    rx={3}
                    ry={3}
                    strokeWidth={2}
                    style={{ cursor: isDragging ? 'grabbing' : 'grab', stroke: "var(--brand-highlight)", fill: "var(--brand-highlight)" }} />
            );
        }}
    </Drag>
}

export { DetailSelector };
