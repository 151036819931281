import { useState } from "react";

type Callback = () => any | Promise<() => any>;

export const useInterval = (func: Callback, seconds: number) => {
  const [id, setId] = useState<NodeJS.Timeout | null>(null);

  const onStart = () => {
    const interval = setInterval(() => {
      func();
    }, seconds * 1000);
    setId(interval);
  };

  const onStop = () => {
    id && clearInterval(id);
  };

  return {
    onStart,
    onStop,
  };
};
