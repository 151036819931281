import { Banner, Icon, Loading, MultiSelect, Select } from "@quantumcast/ui";
import React, { Fragment } from "react";
import { t } from "../../../../../global/Localization/translation";
import ConfigContext from "../../../../Config/ConfigContext";
import FileContext from "../../../FilesContext";
import { AmbiguousMetaDatum, RawFileMetaDatum } from "../../../types";
import { useMachine } from "@xstate/react";
import { MetaDatumContext, metaDatumMachine } from "./metaDatumMachine";

import "./MetaDatum.scss";

type Props = {
  onChange: (data: RawFileMetaDatum) => void;
} & (
  | {
      datum: RawFileMetaDatum;
      intent: "update";
      fileId: string;
    }
  | {
      datum: AmbiguousMetaDatum;
      intent: "multi-update";
      onDelete: (key: string) => void;
    }
  | {
      datum: Partial<RawFileMetaDatum> & {
        type: RawFileMetaDatum["type"];
      };
      intent: "create";
      disabledKeys?: string[];
      fileId: string;
    }
);

const PROTECTED_DATA = ["artist", "title"];

const MetaDatum = (props: Props) => {
  const isMultiUpdate = props.intent === "multi-update";
  const [state, send] = useMachine(metaDatumMachine, {
    devTools: true,
    // @ts-expect-error
    context: {
      datum: props.datum,
      intent: props.intent,
    },

    actions: {
      // @ts-expect-error
      updateParent: (context) => props.onChange(context.datum),
    },
    services: {
      // @ts-expect-error
      deleteDatum: async (context) => {
        if (isMultiUpdate) {
          props.onDelete(context.datum.key);
          return;
        } else {
          // TODO: get error
          const result = await files.onDeleteMetaDatum(
            props.fileId,
            (context.datum as RawFileMetaDatum).id
          );
          return result;
        }
      },
      updating: async (context: MetaDatumContext) => {
        if (isMultiUpdate) {
          props.onChange(context.datum as RawFileMetaDatum);
          return await Promise.resolve();
        } else {
          // @ts-expect-error
          return await files.onUpdateMetaDatum(props.fileId, context.datum);
        }
      },
    },
  });

  const config = React.useContext(ConfigContext);
  const files = React.useContext(FileContext);
  const [options, setOptions] = React.useState<string[]>([]);
  const isProtected = PROTECTED_DATA.includes(
    (props.datum.key || "").toLowerCase()
  );

  const onSearchValue = async (_: string, search: string) => {
    const newOptions = await config.onSearchMetaDataValues(search);
    setOptions(newOptions);
  };

  const onAddToValue = (it: string) => {
    const selections = state.context.datum.value
      ? Object.keys(state.context.datum.value).map((value) => ({
          value,
          label: value,
        }))
      : [];
    send({
      type: "change",
      payload: {
        name: "value",
        option: [...selections, { value: it, label: it }],
      },
    });
  };

  const isEmptyString = state.context.datum.value === "";

  const additionalValue = isMultiUpdate
    ? // @ts-expect-error
      state.context.datum.additionalValue
    : undefined;
  let renderedValue = (
    <div className="meta-datum__value-text">
      {t("file.metaData.unknownValueType")}
    </div>
  );
  if (state.context.datum.type === "TEXT") {
    renderedValue = (
      <Select
        name="value"
        placeholder={
          isEmptyString ? t("file.metaData.text") : t("file.metaData.mixed")
        }
        value={
          state.context.datum.value
            ? {
                value: state.context.datum.value,
                label: state.context.datum.value,
              }
            : undefined
        }
        isCreateable={true}
        onChange={(name, option) => {
          // @ts-expect-error
          return send({ type: "change", payload: { name, option } });
        }}
        onChangeInput={onSearchValue}
        options={options.map((value) => ({ label: value, value }))}
      />
    );
  } else if (state.context.datum.type === "TEXT_LIST") {
    renderedValue = (
      <>
        <MultiSelect
          name="value"
          placeholder={t("file.metaData.tag")}
          value={
            state.context.datum.value
              ? Object.keys(state.context.datum.value).map((value) => ({
                  value,
                  label: value,
                }))
              : []
          }
          isCreateable={true}
          onChange={(name, option) => {
            // @ts-expect-error
            return send({ type: "change", payload: { name, option } });
          }}
          onChangeInput={onSearchValue}
          // TODO: options might not be loaded yet
          options={options.map((value) => ({ label: value, value }))}
        />
        {additionalValue && Object.keys(additionalValue).length > 0 && (
          <div className="meta-datum__additional-values">
            {t("file.metaData.additionalValue")}{" "}
            {Object.keys(additionalValue).map((it, index, array) => (
              <Fragment key={it}>
                <span
                  tabIndex={0}
                  onClick={() => onAddToValue(it)}
                  onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
                    console.log("e.key", e.key);
                    if (e.key === "Enter" || e.key === " ") {
                      onAddToValue(it);
                    }
                  }}
                >
                  {it}
                </span>
                {index + 1 < array.length ? ", " : null}
              </Fragment>
            ))}
          </div>
        )}
      </>
    );
  }

  let keyOptions = config.metaDataKeys;
  if (props.intent === "create" && props.disabledKeys) {
    keyOptions = keyOptions.filter(
      (it) => !props.disabledKeys.includes(it.value)
    );
  }

  return (
    <div className="meta-datum">
      {state.context.error && (
        <div className="meta-datum__error">
          <Banner appearance="error">{state.context.error}</Banner>
        </div>
      )}
      <div className="meta-datum__details">
        <div className="meta-datum__data">
          <div className="meta-datum__key">
            <Select
              name="key"
              isCreateable={true}
              value={
                state.context.datum.key
                  ? {
                      value: state.context.datum.key,
                      label: state.context.datum.key,
                    }
                  : undefined
              }
              isDisabled={isMultiUpdate}
              placeholder={t("file.metaData.key")}
              options={keyOptions}
              onChange={(name, option) => {
                if (state.matches("createFlow.idle")) {
                  // @ts-expect-error
                  return send({ type: "change", payload: { name, option } });
                }
              }}
            />
          </div>
          <div className="meta-datum__type">
            <Select
              name="type"
              value={
                state.context.datum.type
                  ? {
                      value: state.context.datum.type,
                      label: t(
                        `file.metaData.labels.${state.context.datum.type}`
                      ),
                    }
                  : {
                      value: t(`file.metaData.labels.mixed`),
                      label: t(`file.metaData.labels.mixed`),
                    }
              }
              options={config.metaDataTypes}
              onChange={(name, option) => {
                // @ts-expect-error
                return send({ type: "change", payload: { name, option } });
              }}
            />
          </div>
          <div className="meta-datum__value">{renderedValue}</div>
        </div>

        {["update", "multi-update"].includes(state.context.intent) ? (
          <div className="meta-datum__action">
            {state.matches({ updateFlow: "idle" }) ? (
              isProtected ? (
                <div
                  className="meta-datum__delete meta-datum__delete--forbidden"
                  title={t("file.metaData.delete")}
                >
                  <Icon.IonIcons5.IoTrashBinSharp />
                </div>
              ) : (
                <div
                  className="meta-datum__delete"
                  onClick={() => {
                    send({ type: "delete" });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      send({ type: "delete" });
                    }
                  }}
                  tabIndex={0}
                  title={t("file.metaData.delete")}
                >
                  <Icon.IonIcons5.IoTrashBinSharp />
                </div>
              )
            ) : (
              <Loading delay={0} />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MetaDatum;
