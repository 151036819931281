import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@atlaskit/modal-dialog";
import { Button, Select } from "@quantumcast/ui";
import { useSelector } from "@xstate/react";
import { useContext } from "react";
import { send } from "xstate/lib/actions";
import { t } from "../../../../global/Localization/translation";
import ConfigContext from "../../../Config/ConfigContext";
import { selectors } from "./cueMachine";
import { CueStateContext } from "./CueStateContext";

const { selectGroupedCues } = selectors;

const selectValue = (state) => state.context.value;
const selectCanSave = (state) => state.can("save");

const CueKeyModal = () => {
  const config = useContext(ConfigContext);
  const { cueService, addCueKeyModalService } = useContext(CueStateContext);

  const canSave = useSelector(addCueKeyModalService, selectCanSave);

  const value = useSelector(addCueKeyModalService, selectValue);
  const allCueKeys = config.cueKeys.map((cueKey) => cueKey.label);
  const usedCueKeys = useSelector(cueService, selectGroupedCues);
  const unusedCueKeys = allCueKeys.filter(
    (cueKey) => !Object.keys(usedCueKeys).includes(cueKey)
  );
  const notAllowedCueKeys = allCueKeys.filter((cueKey) =>
    Object.keys(usedCueKeys).includes(cueKey)
  );

  return (
    <Modal
      width={"small"}
      onClose={() => send({ type: "close" })}
      onOpenComplete={() =>
        addCueKeyModalService.send({
          type: "updateNotAllowedCueKeys",
          notAllowedCueKeys,
        })
      }
    >
      <ModalHeader>
        <ModalTitle>{t("file.cues.addKeyTitle")}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Select
          name="key"
          isCreateable={true}
          value={value}
          placeholder={t("file.cues.addKeyPlaceholder")}
          options={unusedCueKeys.map((cueKey) => ({
            value: cueKey,
            label: cueKey,
          }))}
          onChange={(name, option) => {
            addCueKeyModalService.send({ type: "updateValue", value: option });
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          appearance="secondary"
          onClick={() => {
            addCueKeyModalService.send("close");
          }}
        >
          {t("action.cancel")}
        </Button>
        <Button
          appearance="primary"
          isDisabled={!canSave}
          onClick={() => addCueKeyModalService.send("save")}
        >
          {t("action.save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export { CueKeyModal };
