import { yupResolver } from "@hookform/resolvers/yup";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  Banner,
  Button,
  Headline,
  Input,
  Form,
  FormActions,
  FormRow,
  Layout,
} from "@quantumcast/ui";
import { t } from "../Localization/translation";
import AuthContext from "./AuthContext";
import { OnLogin } from "./types";

type FormData = {
  email: string;
  password: string;
};

const ForgotPassword = () => {
  const auth = React.useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const schema = React.useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email(t("error.email.invalid"))
          .required(t("error.email.required")),
      }),
    []
  );
  const { register, errors, handleSubmit } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    setError(null);
    const error: OnLogin = await auth
      .onForgotPassword(data.email)
      .catch((r) => r);

    setIsLoading(false);
    if (error) {
      setError(t(`error.http.${error.type}`));
    } else {
      setIsSuccess(true);
    }
  };

  return (
    <Layout.Login>
      {isSuccess ? (
        <>
          <Headline level={1}>{t("forgotPassword.success.headline")}</Headline>
          {t("forgotPassword.success.text")}
        </>
      ) : (
        <>
          <Headline level={1}>{t("forgotPassword.headline")}</Headline>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {error && (
              <FormRow>
                <Banner appearance="error">{error}</Banner>
              </FormRow>
            )}
            <FormRow>
              <Input
                ref={register}
                name="email"
                placeholder={t("forgotPassword.email")}
                label={t("forgotPassword.email")}
                error={errors.email && errors.email.message}
                isInvalid={Boolean(errors.email)}
              />
            </FormRow>
            <FormActions
              right={
                <Button type="submit" isLoading={isLoading}>
                  {t("forgotPassword.submit")}
                </Button>
              }
            />
          </Form>
        </>
      )}
    </Layout.Login>
  );
};

export default ForgotPassword;
