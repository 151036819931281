import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader, ModalTitle
} from '@atlaskit/modal-dialog';
import { Button } from '@quantumcast/ui';
import { useSelector } from '@xstate/react';
import { useContext } from 'react';
import { t } from '../../../../../global/Localization/translation';
import { selectors } from '../cueMachine';
import { CueStateContext } from '../CueStateContext';
const { selectSelectedGroup } = selectors

const DeleteCueKeyModal = () => {
    const { cueService, deleteCueKeyModalService } = useContext(CueStateContext)
    const selectedGroup = useSelector(cueService, selectSelectedGroup)
    const isDeleting = useSelector(deleteCueKeyModalService, state =>  state.matches('deleting'))
    const handClose = () => deleteCueKeyModalService.send('close')
    const handleConfirm = () => {
        // TODO: this will show `default` in the modal before the request is done
        // TODO: this can also not handle server errors
        cueService.send({type: 'cueKeys', value: 'default'})
        deleteCueKeyModalService.send('confirm')
    }

    return <Modal
        width={"small"}
        onClose={handClose}
    >
        <ModalHeader>
            <ModalTitle>{t('file.cues.deleteTitle', {selectedGroup})}</ModalTitle>
        </ModalHeader>
        <ModalBody>
            {t('file.cues.deleteInfo', {selectedGroup})}
        </ModalBody>
        <ModalFooter>
            <Button
                appearance="secondary"
                onClick={handClose}
            >
                {t('action.cancel')}
            </Button>
            <Button
                appearance="danger"
                onClick={handleConfirm}
                isLoading={isDeleting}
            >
                {t('action.delete')}
            </Button>
        </ModalFooter>
    </Modal>
}

export { DeleteCueKeyModal }