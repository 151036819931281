import { yupResolver } from "@hookform/resolvers/yup";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  Banner,
  Button,
  Headline,
  Input,
  Form,
  FormActions,
  FormRow,
  Layout,
  Link,
} from "@quantumcast/ui";
import { t } from "../Localization/translation";
import AuthContext from "./AuthContext";
import { OnLogin } from "./types";

type LoginFormData = {
  email: string;
  password: string;
};

const Login = () => {
  const auth = React.useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const schema = React.useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email(t("error.email.invalid"))
          .required(t("error.email.required")),
        password: yup.string().required(t("error.password.required")),
      }),
    []
  );
  const { register, errors, handleSubmit, setValue } = useForm<LoginFormData>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: LoginFormData) => {
    setIsLoading(true);
    setError(null);
    const error: OnLogin = await auth
      .onLogin(data.email, data.password)
      .catch((r) => r);

    if (error) {
      setIsLoading(false);
      setValue("password", "");
      setError(t(`error.http.${error.type}`));
    }
  };

  return (
    <Layout.Login>
      <Headline level={1}>{t("login.headline")}</Headline>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {error && (
          <FormRow>
            <Banner appearance="error">{error}</Banner>
          </FormRow>
        )}
        <FormRow>
          <Input
            ref={register}
            name="email"
            placeholder={t("login.email")}
            label={t("login.email")}
            error={errors.email && errors.email.message}
            isInvalid={Boolean(errors.email)}
          />
        </FormRow>
        <FormRow>
          <Input
            ref={register}
            name="password"
            placeholder={t("login.password")}
            type="password"
            label={t("login.password")}
            error={errors.password && errors.password.message}
            isInvalid={Boolean(errors.password)}
          />
        </FormRow>
        <FormActions
          left={<Link to="/forgot-password">{t("login.forgotPassword")}</Link>}
          right={
            <Button type="submit" isLoading={isLoading}>
              {t("login.login")}
            </Button>
          }
        />
      </Form>
    </Layout.Login>
  );
};

export default Login;
