import { Redirect, Route, Switch } from "react-router-dom";
import Audiofiles from "../../features/Files/Files";
import PlaylistDetail from "../../features/Playlists/PlaylistDetail/PlaylistDetail";
import Playlists from "../../features/Playlists/Playlists";
import ForgotPassword from "../Auth/ForgotPassword";
import Logout from "../Auth/Logout";
import ResetPassword from "../Auth/ResetPassword";
import PrivateRoute from "./PrivateRoute";

const Routes = () => {
  return (
    <Switch>
      {/*
            features
        */}
      <Route path="/" exact={true}>
        <PrivateRoute>
          <Redirect to="/files" />
        </PrivateRoute>
      </Route>

      <Route path="/files" exact={true}>
        <PrivateRoute>
          <Audiofiles />
        </PrivateRoute>
      </Route>

      <Route path="/playlists" exact={true}>
        <PrivateRoute>
          <Playlists />
        </PrivateRoute>
      </Route>
      
      <Route path="/playlists/:library/:id">
        <PrivateRoute>
          <PlaylistDetail />
        </PrivateRoute>
      </Route>

      {/*
            Global features
        */}
      <Route path="/forgot-password" exact={true}>
        <ForgotPassword />
      </Route>

      <Route path="/reset-password/:token" exact={true}>
        <ResetPassword />
      </Route>

      <Route path="/logout" exact={true}>
        <Logout />
      </Route>
    </Switch>
  );
};

export default Routes;
