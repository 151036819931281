import { HttpError } from "@klw/fetch/lib/types";
import { assign, createMachine } from "xstate";
import { PlaylistPreview } from "../../domain";

export type Context = {
  id: string;
  preview: PlaylistPreview | undefined;
  error?: string;
};

export type Event = { type: "LOAD_PREVIEW" };

export type Services = {
  loadPreview: { data: PlaylistPreview | HttpError | Error };
};

export const playlistPreviewMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAcA2BDAnqglrALgAoBOYAbjmAO4Cy6AxgBY4B2YAdAJIAiAMgKIBiXgHkAgtwD6hAEr8Aap34B1ANoAGALqIUAe1g58OXSx0gAHogCMAZhvsArAA4nDgCw2A7ACYnATnUnHwAaEExrTzdHVz8ANjc-WzcrdVibAF900LQsXAISckpaBmY2dlEJTgA5AHFBCBMOVjJdAGsOHOw8IlIKajomVg4K7mqahGbdenQjEw1NebNkfUNjUyQLRAdfdhtvKzT1GysHG2c3UPCEK08naNjY9WcnNJsnQMzsjC783qKB0rDcSjWqCMDEYi6YjsHL4ABmUIAtjDvnkeoV+iUhuVgWMJiwWtNZix5osNssDMSzJZrnZoq4PD5-IEQmFEE4rOw3K5Yrd3N4Ht4BZ8QJ00QU+sVBmURvxuMJgdI5IoVGS9JS1tStjYoi83Icju5YlZvJd2d4ua4jp4HLE-J4dU43JksiAWLoIHAlqjuhL-li2EsVlSNjSALSxM0ICMisW+v6Y6UcHgCIMakxahBuU1s66BdhBAUCpzedRWE0vWM+34YqWAnGVWpp1YZ0OIY6xdg2xI3ZLeN4+C65vwOLuBW1uByBPaxIVV3Lx2sA7Gy7jNkOgGmeE0F4s28sHbaeKPl+yF2exEtlitOF3pIA */
  createMachine(
    {
      id: "playlistPreviewMachine",
      schema: {
        context: {} as Context,
        events: {} as Event,
        services: {} as Services,
      },
      tsTypes: {} as import("./playlistPreviewMachine.typegen").Typegen0,
      initial: "IDLE",
      states: {
        IDLE: {
          on: {
            LOAD_PREVIEW: {
              target: "LOADING",
              actions: "reset",
            },
          },
        },
        LOADING: {
          invoke: {
            src: "loadPreview",
            onDone: [
              {
                target: "LOADED",
                actions: "assignPreview",
              },
            ],
            onError: [
              {
                target: "IDLE",
                actions: "assignError",
              },
            ],
          },
        },
        LOADED: {
          on: {
            LOAD_PREVIEW: {
              target: "LOADING",
              actions: "reset",
            },
          },
        },
      },
    },
    {
      actions: {
        assignPreview: assign((context, event) => {
          if (event.data instanceof PlaylistPreview) {
            return {
              ...context,
              preview: event.data,
            };
          }
        }),
        reset: assign((context) => ({
          ...context,
          preview: undefined,
          error: undefined,
        })),
        assignError: assign((_, event) => {
          return ({
            error: "playlist.detail.preview.error",
          })
        }),
      },
    }
  );
