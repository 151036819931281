import { Button, Modal, Dropzone } from "@quantumcast/ui";
import React from "react";
import { t } from "../../../global/Localization/translation";
import { useToggle } from "../../../utils/useToggle";
import FileContext from "../FilesContext";
import { reducer } from "./reducer";
import "./AddFiles.scss";

const AddFiles = () => {
  const files = React.useContext(FileContext);
  const [uploads, dispatchProgress] = React.useReducer(reducer, []);
  const modal = useToggle();

  const onProgress = (id: string, progress: number) => {
    dispatchProgress({ type: "PROGRESS", id, progress });
  };

  const onDrop = (newFiles: any[]) => {
    const newUploads = newFiles.map((file, i) => {
      const id = `${file.name}-${i}`;
      files.onUpload(file, (progress: number) => onProgress(id, progress));
      return {
        id,
        name: file.name,
        progress: 0,
      };
    });
    dispatchProgress({ type: "START", files: newUploads });
  };

  const onDone = () => {
    dispatchProgress({ type: "STOP" });
    modal.onToggle();
  };

  return (
    <>
      <Button onClick={modal.onToggle}>{t("file.upload.title")}</Button>
      <Modal
        isOpen={modal.isTrue}
        onClose={modal.onToggle}
        title={t("file.upload.title")}
        actions={
          <>
            <div className="add-files__info">{t("file.upload.info")}</div>
            <Button onClick={onDone}>{t("action.done")}</Button>
          </>
        }
      >
        <div className="add-files">
          {uploads.length === 0 ? (
            <Dropzone onDrop={onDrop}>
              <div className="add-files__dropzone">
                <div className="add-files__drop-info">
                  {t("file.upload.dropzone")}
                </div>
              </div>
            </Dropzone>
          ) : (
            <div className="add-files__uploads">
              {uploads.map((upload) => (
                <div key={upload.id} className="add-files__upload">
                  <div
                    className="add-files__upload-bar"
                    style={{ width: `${upload.progress}%` }}
                  />
                  <div className="add-files__upload-name">{upload.name}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default AddFiles;
