import { t } from "../../../../global/Localization/translation";
import { transformMillisecondsToStringDuration } from "../../../../utils/time";
import { RawFileCoreDatum } from "../../types";
import "./CoreData.scss";

interface Props {
  data: RawFileCoreDatum[];
}

const CoreData = (props: Props) => {
  return (
    <div className="core-data">
      {props.data.map((datum, i) => (
        <div
          key={`${datum.label}-${i}`}
          className="core-datum"
          title={translationKeyToSearchParam(datum.label)}
        >
          <div className="core-datum__label">{t(datum.label)}</div>
          {
          datum.label.includes('duration') ? 
            <div className="core-datum__value">{transformMillisecondsToStringDuration(parseInt(datum.value))}</div> : 
            <div className="core-datum__value">{datum.value}</div>
          }
          
        </div>
      ))}
    </div>
  );
};

const translationKeyToSearchParam = (key: string) => {
  return key.replace("file.coreData", "core");
};

export default CoreData;
