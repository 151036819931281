import { HttpError } from "@klw/fetch/lib/types";
import {
  AreaSelect,
  Banner,
  Field,
  FormRow,
  Input,
  Loading,
  Option,
  Select,
} from "@quantumcast/ui";
import { AreaOption } from "@quantumcast/ui/src/atoms/AreaSelect/types";
import * as React from "react";
import { t } from "../../../../global/Localization/translation";
import ConfigContext from "../../../Config/ConfigContext";
import { Playlist } from "../../domain";
import PlaylistsContext from "../../PlaylistsContext";
import { PlaylistRuleAdditive, PlaylistRuleRestriction } from "../../types";
import AddPlaylistAdditive from "./AddPlaylistAdditive/AddPlaylistAdditive";
import AddPlaylistRestriction from "./AddPlaylistRestriction/AddPlaylistRestriction";
import PlaylistAdditive from "./PlaylistAdditive/PlaylistAdditive";
import PlaylistRestriction from "./PlaylistRestriction/PlaylistRestriction";

import "./PlaylistSettings.scss";

export const PLAYLIST_TYPE_STATIC = "STATIC"
export const PLAYLIST_TYPE_AUTOGENERATED = "AUTOGENERATED"
const PLAYTIME_VALUES = [1, 6, 12, 24, 48];
const TYPE_OPTIONS: AreaOption[] = [
  {
    title: t("playlist.settings.types.autogenerated.title"),
    text: t("playlist.settings.types.autogenerated.text"),
    value: "AUTOGENERATED",
  },
  {
    title: t("playlist.settings.types.manual.title"),
    text: t("playlist.settings.types.manual.text"),
    value: PLAYLIST_TYPE_STATIC,
  },
];

type Props = {
  playlist: Playlist;
  onIsPlaylistActive: (active: boolean | undefined) => void;
};

const PlaylistSettings = (props: Props) => {
  const playlists = React.useContext(PlaylistsContext);
  const config = React.useContext(ConfigContext);
  const [name, setName] = React.useState(props.playlist.name);
  const [cues, setCues] = React.useState<Option>({
    label: props.playlist.cues,
    value: props.playlist.cues,
  });
  const initialType = TYPE_OPTIONS.find(
    (it) => it.value === props.playlist.type
  );
  const [type, setType] = React.useState<AreaOption>(initialType);
  const [playtime, setPlaytime] = React.useState<Option>({
    label: props.playlist.playtime.toString(),
    value: props.playlist.playtime.toString(),
  });
  const [additives, setAdditives] = React.useState<PlaylistRuleAdditive[]>(
    props.playlist.rules.additives
  );
  const [restrictions, setRestrictions] = React.useState<
    PlaylistRuleRestriction[]
  >(props.playlist.rules.restrictions);
  const [error, setError] = React.useState("");
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [loaders, setLoaders] = React.useState<string[]>([]);

  React.useEffect(() => {
    setAdditives(props.playlist.rules.additives);
    setRestrictions(props.playlist.rules.restrictions);
  }, [props.playlist.rules]);

  const onChangeName = (e: React.FormEvent<HTMLInputElement>) => {
    // @ts-expect-error
    const newName = e.target.value;
    setName(newName);
    if (!newName) {
      setErrors({ ...errors, name: t("error.generic.required") });
    } else {
      const { name, ...rest } = errors;
      setErrors({ ...rest });
    }
  };

  const onChangeCues = async (name: string, option: Option) => {
    addLoader(name);
    setCues(option);
    await playlists
      .onSave(props.playlist.id, { [name]: option.value })
      .catch((error: HttpError) => {
        setError(error.description || t("error.generic.save"));
      });
    removeLoader(name);
  };

  const onChangeType = async (name: string, option: AreaOption) => {
    addLoader(name);
    setType(option);

    // Tell the parent element whether the playlist is dynamic or manual
    props.onIsPlaylistActive(option.value === PLAYLIST_TYPE_STATIC)

    await playlists
      .onSave(props.playlist.id, { [name]: option.value })
      .catch((error: HttpError) => {
        setError(error.description || t("error.generic.save"));
      });
    removeLoader(name);
  };

  const onChangePlaytime = async (name: string, option: Option) => {
    addLoader(name);
    setPlaytime(option);
    await playlists
      .onSave(props.playlist.id, { [name]: parseInt(option.value) })
      .catch((error: HttpError) => {
        setError(error.description || t("error.generic.save"));
      });
    removeLoader(name);
  };

  const onChangeAdditive = async (
    index: number,
    additive: PlaylistRuleAdditive
  ) => {
    const name = "additives";
    addLoader(name);
    const newAdditives = [...additives];
    newAdditives[index] = additive;
    setAdditives(newAdditives);
    await playlists
      .onSave(props.playlist.id, {
        rules: { restrictions, additives: newAdditives },
      })
      .catch((error: HttpError) => {
        setError(error.description || t("error.generic.save"));
      });
    removeLoader(name);
  };

  const onChangeRestriction = async (
    index: number,
    restriction: PlaylistRuleRestriction
  ) => {
    const name = "restrictions";
    addLoader(name);
    const newRestrictions = [...restrictions];
    newRestrictions[index] = restriction;
    setRestrictions(newRestrictions);
    await playlists
      .onSave(props.playlist.id, {
        rules: { additives, restrictions: newRestrictions },
      })
      .catch((error: HttpError) => {
        setError(error.description || t("error.generic.save"));
      });
    removeLoader(name);
  };

  const onDeleteAdditive = async (index: number) => {
    const name = "additives";
    addLoader(name);
    const newAdditives = [...additives];
    newAdditives.splice(index, 1);
    setAdditives(newAdditives);
    await playlists
      .onSave(props.playlist.id, {
        rules: { restrictions, additives: newAdditives },
      })
      .catch((error: HttpError) => {
        setError(error.description || t("error.generic.save"));
      });
    removeLoader(name);
  };

  const onDeleteRestriction = async (index: number) => {
    const name = "restrictions";
    addLoader(name);
    const newRestrictions = [...restrictions];
    newRestrictions.splice(index, 1);
    setRestrictions(newRestrictions);
    await playlists
      .onSave(props.playlist.id, {
        rules: { additives, restrictions: newRestrictions },
      })
      .catch((error: HttpError) => {
        setError(error.description || t("error.generic.save"));
      });
    removeLoader(name);
  };

  const onSaveName = async () => {
    if (name) {
      const fieldName = "name";
      addLoader(fieldName);
      await playlists
        .onSave(props.playlist.id, { name })
        .catch((error: HttpError) => {
          setError(error.description || t("error.generic.save"));
        });
      removeLoader(fieldName);
    }
  };

  const addLoader = (fieldName: string) => {
    setLoaders([...loaders, fieldName]);
  };

  const removeLoader = (fieldName: string) => {
    const newLoaders = [...loaders].filter((it) => it !== fieldName);
    setLoaders(newLoaders);
  };

  return (
    <div className="playlist-settings">
      {error && (
        <div className="playlist-settings__error">
          <Banner appearance="error">{error}</Banner>
        </div>
      )}
      <FormRow>
        <Input
          name="name"
          placeholder={t("playlist.settings.name")}
          label={
            <SettingLabel
              title={t("playlist.settings.name")}
              isLoading={loaders.includes("name")}
            />
          }
          value={name}
          onChange={onChangeName}
          onBlur={onSaveName}
          error={errors.name && errors.name}
          isInvalid={Boolean(errors.name)}
        />
      </FormRow>
      <FormRow>
        <Select
          name="cues"
          placeholder={t("playlist.settings.cues")}
          label={
            <SettingLabel
              title={t("playlist.settings.cues")}
              isLoading={loaders.includes("cues")}
            />
          }
          options={config.cueKeys}
          value={cues}
          onChange={onChangeCues}
        />
      </FormRow>
      <FormRow>
        <AreaSelect
          name="type"
          label={
            <SettingLabel
              title={t("playlist.settings.type")}
              isLoading={loaders.includes("type")}
            />
          }
          value={type}
          options={TYPE_OPTIONS}
          onChange={onChangeType}
        />
      </FormRow>
      <FormRow>
        <Select
          name="playtime"
          placeholder={t("playlist.settings.playtime")}
          label={
            <SettingLabel
              title={t("playlist.settings.playtime")}
              isLoading={loaders.includes("playtime")}
            />
          }
          options={PLAYTIME_VALUES.map((it) => ({
            label: it.toString(),
            value: it.toString(),
          }))}
          value={playtime}
          onChange={onChangePlaytime}
        />
      </FormRow>
      <FormRow>
        <Field
          label={
            <SettingLabel
              title={t("playlist.settings.rules.additive.title")}
              isLoading={loaders.includes("additives")}
            />
          }
        >
          {additives.map((additive, index) => (
            <PlaylistAdditive
              type="edit"
              key={index}
              index={index}
              metaDataKeys={config.metaDataKeys}
              additive={additive}
              onSearchMetaDataValues={config.onSearchMetaDataValues}
              onChange={onChangeAdditive}
              onDelete={onDeleteAdditive}
            />
          ))}
          <AddPlaylistAdditive playlist={props.playlist} />
        </Field>
      </FormRow>
      <FormRow>
        <Field
          label={
            <SettingLabel
              title={t("playlist.settings.rules.restriction.title")}
              isLoading={loaders.includes("restrictions")}
            />
          }
        >
          {restrictions.map((restriction, index) => (
            <PlaylistRestriction
              type="edit"
              key={index}
              index={index}
              metaDataKeys={config.metaDataKeys}
              restriction={restriction}
              onChange={onChangeRestriction}
              onDelete={onDeleteRestriction}
            />
          ))}
          <AddPlaylistRestriction playlist={props.playlist} />
        </Field>
      </FormRow>
    </div>
  );
};

const SettingLabel = (props: { title: string; isLoading: boolean }) => {
  return (
    <div className="playlist-settings__label">
      {props.title}
      {props.isLoading === true ? (
        <div className="playlist-settings__label-loader">
          <Loading size={15} />
        </div>
      ) : null}
    </div>
  );
};

export default PlaylistSettings;
