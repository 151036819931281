import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { Banner, Button, Input, Label } from "@quantumcast/ui";
import { useMachine } from "@xstate/react";
import React from "react";
import { t } from "../../../global/Localization/translation";
import PlaylistsContext from "../PlaylistsContext";
import "./AddPlaylist.scss";
import { addPlaylistsMachine } from "./addPlaylistsMachine";

const AddPlaylist = () => {
  const playlists = React.useContext(PlaylistsContext);
  const [state, sendEvent] = useMachine(addPlaylistsMachine, {
    context: {
      name: "",
    },
    services: {
      addPlaylist: (context) => playlists.onAdd(context.name),
    },
  });

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    sendEvent({ type: "CHANGE", name: e.currentTarget.value });
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      sendEvent({ type: "SUBMIT" });
    }
  };

  return (
    <div className="delete-playlist">
      <Button onClick={() => sendEvent("OPEN")}>
        {t("playlist.add.title")}
      </Button>
      <ModalTransition>
        {state.matches("MODAL_OPEN") && (
          <Modal onClose={() => sendEvent("CLOSE")}>
            <ModalHeader>
              <ModalTitle>{t("playlist.add.title")}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              {state.context.error && (
                <div className="delete-playlist__error">
                  <Banner appearance="error">{t(state.context.error)}</Banner>
                </div>
              )}
              <Input
                name="name"
                value={state.context.name}
                label={<Label label={t("playlist.add.name")} />}
                placeholder={t("playlists.search.placeholder")}
                onKeyDown={onKeyDown}
                onChange={onChange}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                appearance="secondary"
                onClick={() => sendEvent("CLOSE")}
                type="reset"
              >
                {t("action.cancel")}
              </Button>
              <Button
                type="submit"
                appearance="primary"
                onClick={() => sendEvent({ type: "SUBMIT" })}
                isLoading={state.matches("MODAL_OPEN.PROCESSING")}
                isDisabled={!state.can("SUBMIT")}
              >
                {t("action.save")}
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default AddPlaylist;
