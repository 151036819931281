import { del, get, post, put, setParams } from "@klw/fetch";
import { HttpError } from "@klw/fetch/lib/types";
import * as React from "react";
import AuthContext from "../../global/Auth/AuthContext";
import { DEFAULT_LIBRARY } from "../../global/Auth/const";
import ConfigContext from "../Config/ConfigContext";
import { Playlist, PlaylistPreview } from "./domain";
import {
  RawPlaylist,
  RawPlaylistPreview
} from "./types";

interface IPlaylistsContext {
  isInitialized: boolean;
  isLoading: boolean;
  library: string;
  total: number;
  length: number;
  search: string;
  list: Playlist[];
  onChangeLibrary: (library: string) => void;
  onChangeSearch: (search: string) => void;
  onAdd: (name: string) => Promise<void | HttpError>;
  onLoad: () => Promise<void>;
  onLoadPreview: (id: string) => Promise<PlaylistPreview | HttpError | Error>;
  onDelete: (playlist: Playlist) => Promise<void | HttpError>;
  onSave: (
    id: string,
    playlist: Omit<Partial<Playlist>, "id">
  ) => Promise<void | HttpError>;
}

interface IPlaylistsContextProvider {
  children?: React.ReactNode;
}

const defaultContext = {
  isInitialized: false,
  isLoading: false,
  search: "",
  total: 0,
  list: [] as Playlist[],
};

const PlaylistsContext = React.createContext<IPlaylistsContext>(
  defaultContext as IPlaylistsContext
);

export const PlaylistsContextProvider = (props: IPlaylistsContextProvider) => {
  const auth = React.useContext(AuthContext);
  const config = React.useContext(ConfigContext);
  const { libraries } = auth;
  const library = config.library;
  const [isInitialized, setIsInitialized] = React.useState(
    defaultContext.isInitialized
  );
  const [isLoading, setIsLoading] = React.useState(defaultContext.isLoading);
  const [search, setSearch] = React.useState(defaultContext.search);
  const [total, setTotal] = React.useState(defaultContext.total);
  const [list, setList] = React.useState(defaultContext.list);

  React.useEffect(() => {
    if (libraries.length > 0 && library === DEFAULT_LIBRARY) {
      config.onLoadConfig(libraries[0]);
      setIsInitialized(true);
      config.onLoadConfig(libraries[0]);
    }
  }, [libraries, library, config]);

  React.useEffect(() => {
    if (search) {
      const timeoutId = setTimeout(() => onLoad(), 500);
      return () => clearTimeout(timeoutId);
    } else {
      onLoad();
    }

    // eslint-disable-next-line
  }, [library, search]);

  const onLoad = async () => {
    // Do not load, if not initialized
    if (!isInitialized) {
      return;
    }

    // Not searching, if search string is set but under 3 characters
    if (search && search.length < 2) {
      return;
    }

    setIsLoading(true);
    const uri = setParams(`/api/libraries/${library}/playlists`, {
      search,
      page: 1,
      perPage: search ? undefined : "250",
    });
    const resp = await get(uri);
    if (resp.ok) {
      const newTotal = resp.body?._metaData.total || 0;
      const newPlaylists =
        resp.body?.list?.map((it: RawPlaylist) => new Playlist(it)) || [];
      setTotal(newTotal);
      setList(newPlaylists);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      return;
    }
  };

  const onAdd = async (name: string) => {
    // To add a new playlist only the name is needed, it will be saved as 'AUTOGENERATED'
    let playlist: RawPlaylist = {
      name: name,
    }

    const resp = await post(`/api/libraries/${library}/playlists`, playlist);
    if (resp.ok && !resp.error) {
      onLoad();
      return;
    } else {
      return Promise.reject(resp.error);
    }
  };

  const onDelete = async (playlist: Playlist) => {
    const resp = await del(
      `/api/libraries/${library}/playlists/${playlist.id}`
    );
    if (resp.ok && !resp.error) {
      onLoad();
      return;
    } else {
      return Promise.reject(resp.error);
    }
  };

  const onLoadPreview = async (id: string) => {
    const resp = await get(`/api/libraries/${library}/playlists/${id}/list`);
    if (resp.ok && !resp.error) {
      try {
        const raw: RawPlaylistPreview = resp.body as RawPlaylistPreview;
        return new PlaylistPreview(raw);
      } catch (error) {
        return Promise.reject(error as Error);
      }
    } else {
      return Promise.reject(resp.error);
    }
  };

  const onSave = async (
    id: string,
    playlist: Omit<Partial<Playlist>, "id">
  ) => {
    const resp = await put(
      `/api/libraries/${library}/playlists/${id}`,
      playlist
    );
    if (resp.ok && !resp.error) {
      const raw: RawPlaylist = resp.body as RawPlaylist;
      const index = list.findIndex((it) => it.id === id);
      if (index > -1) {
        const newList = [...list];
        newList[index] = new Playlist(raw);
        setList(newList);
      }
      return;
    } else {
      return Promise.reject(resp.error);
    }
  };

  const state: IPlaylistsContext = {
    isInitialized,
    isLoading,
    library,
    total,
    length: list.length,
    search,
    list,
    onChangeLibrary: config.onLoadConfig,
    onChangeSearch: setSearch,
    onLoad,
    onLoadPreview,
    onAdd,
    onDelete,
    onSave,
  };

  return (
    <PlaylistsContext.Provider value={state}>
      {props.children}
    </PlaylistsContext.Provider>
  );
};

export default PlaylistsContext;
