import { File } from "../Files/domain";
import {
  PlaylistRuleAdditive,
  PlaylistRuleRestriction,
  PlaylistTypeAutogenerated,
  PlaylistTypeStatic,
  RawPlaylist,
  RawPlaylistPreview,
} from "./types";

export class Playlist {
  id: string;
  name: string;
  cues: string;
  playtime: number;
  type: PlaylistTypeAutogenerated | PlaylistTypeStatic;
  rules: {
    additives: PlaylistRuleAdditive[];
    restrictions: PlaylistRuleRestriction[];
  };
  filesIDs?: string[];

  constructor(raw: RawPlaylist) {
    this.id = raw.id;
    this.name = raw.name;
    this.cues = raw.cues;
    this.playtime = raw.playtime;

    // These properties will not be the same for manual playlists
    this.type = raw.type;
    this.rules = raw.rules;
    this.filesIDs = [];
  }
}

export class PlaylistPreview {
  id: string;
  name: string;
  cues: string;
  playtime: number;
  type: PlaylistTypeAutogenerated | PlaylistTypeStatic;
  files: File[];

  constructor(raw: RawPlaylistPreview) {
    this.id = raw.id;
    this.name = raw.name;
    this.cues = raw.cues;
    this.playtime = raw.playtime;
    this.type = raw.type;
    this.files = raw.files.map((it) => new File(it));
  }
}
