import { Icon, Time, useAudioPlayer } from "@quantumcast/ui";
import { useSelector } from "@xstate/react";
import React from "react";
import { useHistory } from "react-router";
import { t } from "../../../../../global/Localization/translation";
import { File } from "../../../../Files/domain";
import "./PlaylistFile.scss";

interface Props {
  file: File;
  playlistCue: string;
  isActive: boolean;
  index?: Number;
  onDelete?: (index: Number) => void
}

const PlaylistFile = (props: Props) => {
  const { playerService } = useAudioPlayer();
  const history = useHistory();
  const isPlaying = useSelector(
    playerService,
    (state) => state.matches("playing") && state.context.url === props.file.url
  );

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    history.push(`/files?search=core.id:${props.file.id}`);
  };

  const onPlay = (e: React.MouseEvent<SVGElement>) => {
    e.stopPropagation();
    playerService.send({
      type: "play",
      data: {
        id: props.file.id,
        title: props.file.title,
        artist: props.file.artist,
        url: props.file.url,
      },
    });
  };

  const onStop = (e: React.MouseEvent<SVGElement>) => {
    e.stopPropagation();
    playerService.send({ type: "stop" });
  };

  const fileCues = props.file.cues.map((it) => it.key);
  const usedCue = fileCues.includes(props.playlistCue)
    ? props.playlistCue
    : `default`;

  return (
    <>
      {props.isActive &&
        <div className="playlist-preview__delete" onClick={() => props.onDelete(props.index)}>
          <Icon.IonIcons5.IoTrashBinSharp
            title={t("playlist.file.delete.title")}/>
        </div>}
      <div onClick={onClick} className="playlist-file">
        <div className="playlist-file__data">
          <div className="playlist-file__title">
            {props.file.title || props.file.archiveNo || props.file.id}
          </div>
          {props.file.artist && (
            <div className="playlist-file__artist">{props.file.artist}</div>
          )}
        </div>
        <div className="playlist-file__cue">{usedCue}</div>
        <div className="playlist-file__player">
          <div className="playlist-file__time">
            <Time time={props.file.duration} />
          </div>
          <div className="playlist-file__play">
            {isPlaying ? (
              <Icon.IonIcons5.IoStop
                size={25}
                onClick={onStop}
                title={t("file.player.stop")}
              />
            ) : (
              <Icon.IonIcons5.IoPlay
                size={25}
                onClick={onPlay}
                title={t("file.player.start")}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlaylistFile;
