import { Layout, Loading } from "@quantumcast/ui";
import * as React from "react";
import { useParams } from "react-router-dom";
import Page from "../../../components/layouts/Page/Page";
import { Playlist } from "../domain";
import PlaylistsContext from "../PlaylistsContext";
import "./PlaylistDetail.scss";
import PlaylistPreview from "./PlaylistPreview/PlaylistPreview";
import PlaylistSettings from "./PlaylistSettings/PlaylistSettings";
import {useState} from "react";

const PlaylistDetail = () => {
  const { library, id } = useParams<{ library?: string; id?: string }>();
  const playlists = React.useContext(PlaylistsContext);
  const [playlist, setPlaylist] = React.useState<Playlist | undefined>(
    playlists.list.find((it) => it.id === id)
  );
  const [isActive, setIsActive] = useState<boolean | undefined>(undefined)

  React.useEffect(() => {
    if (library) {
      playlists.onChangeLibrary(library);
    }
  });

  React.useEffect(() => {
    setPlaylist(playlists.list.find((it) => it.id === id));
  }, [id, playlists.list]);

  if (playlists.isLoading || !playlist) {
    return (
      <Layout.PageLoading>
        <Loading size={75} />
      </Layout.PageLoading>
    );
  }

  return (
    <Page width="large">
      <div className="playlist-detail">
        <div className="playlist-detail__settings">
          <PlaylistSettings onIsPlaylistActive={(active) => setIsActive(active)} playlist={playlist} />
        </div>
        <div className="playlist-detail__preview">
          <PlaylistPreview isActivePlaylist={isActive} playlist={playlist} />
        </div>
      </div>
    </Page>
  );
};

export default PlaylistDetail;
