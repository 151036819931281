import { HttpError } from "@klw/fetch/lib/types";
import { assign, createMachine } from "xstate";
import { Playlist } from "../../../domain";

export type PlaylistContext = {
  error?: string;
  playlist?: Playlist;
};

export type PlaylistEvents =
  | { type: "OPEN"; playlist: Playlist }
  | { type: "CLOSE" }
  | { type: "DELETE" };

export type PlaylistServices = {
  deletePlaylist: { data: HttpError | void };
  reload: { data: HttpError | void };
};

export const deletePlaylistsMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QTAGzAFzABVQQwE9UBLWDAWTwGMALYgOzADoBJAEQBkBRAYgHlsXAHIBtAAwBdRKAAOAe1jEMxOfWkgAHogCsAJgA0IAogAcARiYAWE9oBsAdnu3blgJyuzJywF9vhlOhYuIQkZJS0DMzkfGwAghwA+gLCrJy8bFzcACpc4lJIIPKKyqrqWgieJkzaJjaW2vYAzJZiJra69obGCI1tTO26lmaulm4tYma+-miYOPhEpBTUdIxM0XGJyUKp3DwAwhx8AMq5kupFSipqBeXt9ky6E-au9vXmTZ1GOmL3jQ5mtjM2ha9iBTimIACs2CCzCy0iaxi8SSgm22AASnw9lwjkcWEIAOI8CCqZgMABucgA1swoUF5qElhFVutkVsmBisTi8YSEBS5FQ8CV6Hk8ucFJdSjdEHcHk8Xm8zB8uohGo1dA8hvYTD8xLYbE4fH5ITN6SFFuEVlEkZtURzMdjcfiiWAAE6uuSupgyfAYABmnoAtkw6XNzXDmdaNiiUpzHTyCXz6JTBcLRWcChdhWVEJVqrVgQ1mq07iqELpXI1qrpdGZ6rZGvY9LptBDQzDGZaEeicVwsllnTwxZmJdnpQg9GWTK4xEwzPPXIDXNpKmIjcb6HIUPACu2GRb4YxxcUrjmEABaVxl8+2Jhie8Px8P2tt01h2FMq07LjHyXXUDlACGrNNoa66I2ri6LYJZTk2d4-HYOpNGIHivoE76doeUZsqiv5jgBqpXl85Z6nefz1JBTS2KBYi6Gh0L7hGX6sraKTsNweGnuOJifN06pVrY7i1IuHRtBM9Fmh+XYsjaMZog63LOpxUoET02r9O4jy9KMTbzmWjQ-EwrygY8AwrvqEkYQekZMD2Jz9kpI4nipmimJ4-SWPYrRiPx+qWGWliCQ8M6OHWlhqoMTS+L4QA */
  createMachine(
    {
      id: "deletePlaylistMachine",
      schema: {
        context: {} as PlaylistContext,
        events: {} as PlaylistEvents,
        services: {} as PlaylistServices,
      },
      tsTypes: {} as import("./deletePlaylistsMachine.typegen").Typegen0,
      initial: "IDLE",
      states: {
        IDLE: {
          on: {
            OPEN: {
              target: "MODAL_OPEN",
              actions: "setPlaylist",
            },
          },
        },
        MODAL_OPEN: {
          initial: "IDLE",
          states: {
            IDLE: {
              on: {
                DELETE: {
                  target: "PROCESSING",
                },
                CLOSE: {
                  target: "#deletePlaylistMachine.IDLE",
                },
              },
            },
            PROCESSING: {
              invoke: {
                src: "deletePlaylist",
                onDone: [
                  {
                    target: "#deletePlaylistMachine.IDLE",
                    actions: "reset",
                  },
                ],
                onError: [
                  {
                    target: "IDLE",
                    actions: "assignError",
                  },
                ],
              },
            },
          },
        },
      },
    },
    {
      actions: {
        setPlaylist: assign((_, event) => ({ playlist: event.playlist })),
        reset: assign({ playlist: undefined, error: undefined }),
        assignError: assign((_, __) => ({
          error: "playlist.delete.error",
        })),
      },
    }
  );
