import { AudioPlayerContext, Banner, Button, Field, Icon, Input } from "@quantumcast/ui";
import { useSelector } from "@xstate/react";
import { useContext } from "react";
import { t } from "../../../../../global/Localization/translation";
import { RawCue } from "../../../types";
import { CueStateContext } from "../CueStateContext";
import "./Cue.scss";

interface Props {
  fileId: string;
  cue: RawCue;

  uniqueName: string;
  isReadOnly?: boolean;
  isInvalid?: boolean;
  canDelete?: boolean;

  onClick?: () => void;
  onMouseOver?: () => void;

  error?: string
}


const { selectCurrentTime } = AudioPlayerContext.selectors
const selectCues = (state) => state.context.groupedCues[state.context.selectedGroup]

const Cue = ({
  canDelete = false,
  isReadOnly = false,
  cue,
  uniqueName,
  isInvalid = false,
  ...props
}: Props) => {
  const { cueService } = useContext(CueStateContext)
  const cues = useSelector(cueService, selectCues)
  const { playerService } = useContext(AudioPlayerContext.Context);
  const currentTime = useSelector(playerService, selectCurrentTime)
  return (
    <div key={`${uniqueName}_${cue.id}`} className="cue">
      {props.error && (
        <div className="cue__error">
          <Banner appearance="error">{props.error}</Banner>
        </div>
      )}
      <fieldset className="cue__details" name={cue.type}>
        <div
          className="cue__data"
          onMouseEnter={(e) => cueService.send({ type: "mouseenter", cueId: cue.id })}

          onMouseLeave={(e) => cueService.send({ type: "mouseleave", cueId: undefined })}>
          

          <div className="cue__value">

            <Field
              label={t(`file.cues.types.${cue.type}`)}
            >
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: ''
              }}>
                {!isReadOnly ? <Button iconBefore={<Icon.IonIcons5.IoAddCircle
                  title={t("file.cues.delete")}
                  color={'var(--brand-primary)'}
                  size={20}
                />} appearance="secondary"
                  onClick={() => cueService.send({ type: 'setCueValue', cueId: cue.id, value: currentTime })}
                /> : null}

                <div style={{ flex: 1 }}>
                  <Input
                    name="value"
                    placeholder={t("file.cues.value")}
                    // @ts-expect-error
                    onChange={(e) => cueService.send({ type: e.type, cueId: cue.id, value: e.currentTarget.value })}
                    onBlur={(e) => cueService.send({ type: "blur", cueId: undefined })}
                    onFocus={(e) => cueService.send({ type: "focus", cueId: cue.id })}

                    value={cues.find(singleCue => singleCue.id === cue.id).stringValue}
                    isReadOnly={isReadOnly}
                    isDisabled={isReadOnly}
                    isInvalid={isInvalid}
                  />
                </div>
              </div>
            </Field>
          </div>
        </div>


      </fieldset>
    </div>
  );
};

export default Cue;
