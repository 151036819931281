import { HttpError } from "@klw/fetch/lib/types";
import { assign, createMachine } from "xstate";
import { Playlist } from "../../domain";

export type Context = {
  error?: string;
  playlist: Playlist;
  success?: string
};

export type Event = { type: "UPDATE_PLAYLIST"; playlist: Playlist };

export type Services = {
  updatePlaylist: { data: HttpError | void };
};

export const updatePlaylistMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEMIQAoBtkE9MEtYAXAWWQGMALfAOzADoBJAEQBkBRAYgHl12A5ANoAGALqJQABwD2sfEXzSaEkAA9EAVgBMAGhA5EADgCM9ACyGNANgDsGk8Zu37AXxd7UGbHkKkK1OnoSbmYAQVYAfV4BJjYuAGUAVQAhEkYAFRFxJBAZOQUlFXUELWMtehsbAE4zDQ0zGwBmW0c9AwRGp3oq4zMreptDKq1hV3cQTyxcAmIyKloGYLDI6P5Yjk4AYQAJUP4AcXYslTz5RWUc4sMbNsQtG1MtYcNaq2MqntsrNw80KZ9Zv4FkEQuEonw1iwNptWNx4kcxCdZGdCpdEP1hPRhFZnoZGmZjBpOlVbggzFVDPQtM1etdLPYbGZGj8Jn9vDM-PNAkswat6OgAErcTbseLxRgHTgQJQMWgAN2kAGsGJN2b45gFFqCVhD+UKRWKJfsEPLpORkAUaFljjlTpaiuiNJjsbj8YTiaSnjYKlo+hpCRZGhonCzVdN1UDudrwTFBcLReLJWAAE7J6TJ+iSbBEABm6YAtvQwwDOZqQcsY2s4wbE8bTebLdbEbbkfa0SVGqYHvYmWYzE9rC9PSZ6I0XsIhrV-W9jG5xjRpBA4CpixyNQskflzg6EABaEn6RC777jVcRrkMKHsTcoi6gK6NTEfYZWTuGa6NfGkxpacpWKwvGYwilEMFJmKGbLhoCF7lryEI3m296II0B7tMYeL0IYwgPDiWEmOS6EQV4UGlsCPI6jEV4Idu7bGKM9AaFUlRjq+LzGJ+uiHh0jLdL0-QNEMIxjL8xEluuUYVny1YJka1GokhZLGI8DzvNcWjBs0nHtAO3QAb61RBtUwjgXOQA */
  createMachine(
    {
      id: "updatePlaylistMachine",
      schema: {
        context: {} as Context,
        events: {} as Event,
        services: {} as Services,
      },
      tsTypes: {} as import("./updatePlaylistMachine.typegen").Typegen0,
      initial: "IDLE",
      states: {
        IDLE: {
          on: {
            UPDATE_PLAYLIST: {
              target: "UPDATE",
              actions: ["reset", "update"],
            },
          },
        },
        UPDATE: {
          invoke: {
            src: "updatePlaylist",
            onDone: [
              {
                target: "IDLE",
                actions: "assignSuccess",
              },
            ],
            onError: [
              {
                target: "IDLE",
                actions: "assignError",
              },
            ],
          }
        }
      }
    },
    {
      actions: {
        update: assign((_, event) => ({
          playlist: event.playlist,
        })),
        reset: assign((context) => ({
          ...context,
          playlist: undefined,
          error: undefined,
          success: undefined
        })),
        assignError: assign((_, __) => ({
          error: "playlist.detail.save.error",
        })),
        assignSuccess: assign((_, __) => ({
          success: "playlist.detail.save.success",
        }))
      },
    }
  );
