import { MouseTouchOrPointerEvent } from "@visx/drag/lib/useDrag";
import { Bar } from "@visx/shape";


type Props = {
  progressX: number;
  height: number
  onDrag?: (e: MouseTouchOrPointerEvent) => void
  onDragEnd?: (e: MouseTouchOrPointerEvent) => void
  onDragMove?: (e: MouseTouchOrPointerEvent) => void
}

const ProgressIndicator = ({
  progressX,
  height,
  onDrag: customOnDrag = (e) => { },
  onDragMove: customOnMove = (e) => { },
  onDragEnd: customOnDragEnd = (e) => { }
}: Props) => {
  const barX = progressX
  return <Bar
    x={Number.isNaN(barX) ? 0 : barX}
    y={height * 0.06 / 2}
    width={2}
    height={height * 0.94}
    rx={2}
    ry={2}
    style={{
      cursor: "col-resize",
      fill: "var(--brand-danger)",
      stroke: "var(--brand-danger)"
    }}
  />

  // return <Drag
  //   width={920}
  //   height={height}
  //   restrict={{
  //     // xMin: 0,
  //     // xMax: width - selectedArea.width,
  //     yMax: 0,
  //     yMin: 0

  //   }}
  //   x={progressX}
  //   y={height * 0.06 / 2}
  // >
  //   {({ dragStart, dragEnd, dragMove, isDragging, x, y, dx, dy }) => {
  //     const handleDragMove = (e) => {
  //       // @ts-expect-error
  //       customOnMove({ e, x, y, dx, dy })
  //       dragMove(e)
  //     }
  //     const handleDragStart = (e) => {
  //       // @ts-expect-error
  //       customOnDrag({ e, x, y, dx, dy })
  //       dragStart(e)
  //     }
  //     const handleDragEnd = (e) => {
  //       // @ts-expect-error
  //       customOnDragEnd({ e, x, y, dx, dy })
  //       dragEnd(e)
  //     }
  //     const barX = isDragging ? clamp(dx, 920) : progressX
  //     return <Bar
  //       // x={progressX}
  //       x={Number.isNaN(barX) ? 0 : barX}
  //       y={height * 0.06 / 2}
  //       width={2}
  //       height={height * 0.94}
  //       rx={2}
  //       ry={2}
  //       onMouseDown={handleDragStart}
  //       onTouchStart={handleDragStart}
  //       onMouseMove={isDragging ? handleDragMove : undefined}
  //       onTouchMove={isDragging ? handleDragMove : undefined}
  //       onMouseUp={isDragging ? handleDragEnd : undefined}
  //       onTouchEnd={isDragging ? handleDragEnd : undefined}

  //       style={{
  //         cursor: "col-resize",
  //         fill: "var(--brand-danger)",
  //         stroke: "var(--brand-danger)"
  //       }}
  //     />

  //   }}
  // </Drag>
}

export { ProgressIndicator };
