import { RawFileMetaDatum } from "../../../types";
import { createMachine } from "xstate";
import { assign } from "xstate";
import { z } from "zod";

export const addMetaDataMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QEEIQLJgC4EMAiOu6A9hDgDYB0AlhOWAMTEAOYAdiWeQNoAMAuolDNisalmrE2QkAA9EAVgBMAGhABPRAA4llAJx6AzLwCMANl5bDhvb0NmAvg7WoM2fIRycKlALakKAHlWNkgGAGNyUTA+QSQQETEJKRl5BAB2I0prJUMFdIAWPQUzfMM1TQR7XT0lYqLeJXSzYr0Cpxc0TFwCIgCqfy5g9khKSHFuj1wGWBwANzBJ3pxYmUTxSWl4tKUtM30tBVtDJQLM+3KNREN0hWytdK0TYwUCusMtDpBXJc9vAf6w1CEDGEAm7mWDDmFFohEWEMIAFdfKt4utkltQGkHpReHj8QT8SYKogmoZKOljCY9M9ck90pSvj8EX0uH5ASFRrM5tQ2FAGBApGAaGw5sQANbC5k9P79dlDTkg7m8qAIXli8KETaxVHCUQbFLbRDnbInPKFYqlSkkhBKUq43ZKXaGEzKQwFRxfNikOAyaVTLz9Nb6jGpRAAWi0+gMMdjMb2NvD+0JxiUvDMz1en2c3y6LMDbNo9GDSU2YYQeislBKCi0vEej0OpRtdV4B2plNuRje2c6bhlrJ8gyCipLBsxcmuelxhNnpgKNpMWgKFJuJnSTupTw+hiZeYHBaHHJGIPGWF+uDHoaNCBMJjbZjJRhOryUd+JVyqt0ombszRpljNLuOb+ss-zyiOJ6UMqfJXmWN5HCudRWBmtTKPWH6VHkJiUGYHyPCYyFnAUJhOE4QA */
  createMachine(
    {
      tsTypes: {} as import("./metaDataMachine.typegen").Typegen0,
      schema: {
        context: {} as {
          files: any;
          fileId: string;
          datum: Partial<RawFileMetaDatum> & {
            type: RawFileMetaDatum["type"];
          };
        },
        services: {} as {
          addMetaDatum: { data: void };
        },
        events: {} as
          | {
              type: "validateMetaDatum";
              payload: Partial<RawFileMetaDatum> & {
                type: RawFileMetaDatum["type"];
              };
            }
          | { type: "openModal" }
          | { type: "saveMetaData" }
          | { type: "close" },
      },
      initial: "idle",
      id: "AddMetaDataModal",
      states: {
        idle: {
          on: {
            openModal: {
              target: "modalOpened",
            },
          },
        },
        modalOpened: {
          initial: "editMetaData",
          states: {
            editMetaData: {
              on: {
                saveMetaData: {
                  target: "saving",
                  cond: "isValidMetaDatum",
                },
                validateMetaDatum: {
                  actions: "storeMetaDatum",
                },
              },
            },
            saving: {
              invoke: {
                src: "addMetaDatum",
                onDone: [
                  {
                    target: "#AddMetaDataModal.idle",
                    actions: "discardMetaDatum",
                  },
                ],
              },
            },
          },
          on: {
            close: {
              target: "idle",
              actions: "discardMetaDatum",
            },
          },
        },
      },
    },
    {
      guards: {
        isValidMetaDatum: (context, event) => {
          const metaDatumSchema = z.discriminatedUnion("type", [
            z.object({
              key: z.string().min(1),
              type: z.literal("TEXT"),
              value: z.string().min(1),
            }),
            z.object({
              key: z.string().min(1),
              type: z.literal("TEXT_LIST"),
              value: z.object({}),
            }),
          ]);

          const result = metaDatumSchema.safeParse(context.datum);
          return result.success;
        },
      },
      actions: {
        /**
         * discard metaDatum in context after it has been persisted or
         * discarded (modal was closed without saving)
         */
        discardMetaDatum: assign({
          datum: (context, event) =>
            // TODO: how do type deal with the initialState, which is an "empty" MetaDatum
            ({} as RawFileMetaDatum),
        }),
        storeMetaDatum: assign({
          datum: (context, event) => event.payload,
        }),
      },
    }
  );
