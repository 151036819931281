import { get, setParams } from "@klw/fetch";
import * as React from "react";
import { t } from "../../global/Localization/translation";
import { RawOption } from "./types";
import { DEFAULT_LIBRARY } from "../../global/Auth/const";

interface IConfigContext {
  cueKeys: RawOption[];
  cueTypes: RawOption[];
  library: string;
  metaDataKeys: RawOption[];
  metaDataTypes: RawOption[];
  onLoadConfig: (lib: string) => void;
  onRefetch: () => void;
  onReset: () => void;
  onSearchMetaDataValues: (search: string) => Promise<string[]>;
}

interface IConfigContextProvider {
  children?: React.ReactNode;
}

const defaultContext = {
  cueKeys: [] as RawOption[],
  cueTypes: [] as RawOption[],
  metaDataKeys: [] as RawOption[],
  metaDataTypes: [
    {
      label: t("file.metaData.labels.TEXT"),
      value: "TEXT",
    },
    {
      label: t("file.metaData.labels.TEXT_LIST"),
      value: "TEXT_LIST",
    },
  ] as RawOption[],
};

const ConfigContext = React.createContext<IConfigContext>(
  defaultContext as IConfigContext
);

export const ConfigContextProvider = (props: IConfigContextProvider) => {
  const [library, setLibrary] = React.useState<string>(DEFAULT_LIBRARY);
  const [cueKeys, setCueKeys] = React.useState(defaultContext.cueKeys);
  const [cueTypes, setCueTypes] = React.useState(defaultContext.cueTypes);
  const [metaDataKeys, setMetaDataKeys] = React.useState(
    defaultContext.metaDataKeys
  );

  React.useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, [library]);

  const onLoadConfig = (lib: string) => {
    setLibrary(lib);
  };

  const onLoad = async () => {
    if (library) {
      const resp = await get(`/api/libraries/${library}/config`);
      if (resp.ok) {
        setCueKeys(resp.body?.cueKeys || []);
        setCueTypes(
          resp.body?.cueTypes?.map((x: RawOption) => ({
            ...x,
            label: t(x.label),
          })) || []
        );
        setMetaDataKeys(resp.body?.metaDataKeys || []);
      }
    }
  };

  const onRefetch = () => {
    onLoad();
  };

  const onSearchMetaDataValues = async (search: string): Promise<string[]> => {
    if (library) {
      const uri = setParams(
        `/api/libraries/${library}/config/metaData/values`,
        {
          search,
        }
      );
      const resp = await get(uri);
      if (resp.ok) {
        return resp.body?.list || [];
      }
    }
    return [];
  };

  const onReset = () => {
    setLibrary(null);
    setCueKeys([]);
    setCueTypes([]);
    setMetaDataKeys([]);
  };

  const state: IConfigContext = {
    cueKeys,
    cueTypes,
    library,
    metaDataKeys,
    metaDataTypes: defaultContext.metaDataTypes,
    onLoadConfig,
    onRefetch,
    onReset,
    onSearchMetaDataValues,
  };

  return (
    <ConfigContext.Provider value={state}>
      {props.children}
    </ConfigContext.Provider>
  );
};

export default ConfigContext;
