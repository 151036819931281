import * as React from "react";
import { Loading, Layout, useAudioPlayer } from "@quantumcast/ui";
import AuthContext from "./AuthContext";
import ConfigContext from "../../features/Config/ConfigContext";
import FileContext from "../../features/Files/FilesContext";

const Logout = () => {
  const isTriggered = React.useRef(false);
  const auth = React.useContext(AuthContext);
  const config = React.useContext(ConfigContext);
  const files = React.useContext(FileContext);
  const { playerService } = useAudioPlayer();

  React.useEffect(() => {
    if (isTriggered.current === false) {
      isTriggered.current = true;
      logout();
    }
  });

  const logout = async () => {
    await auth.onLogout();
    files.onReset();
    config.onReset();
    playerService.send({ type: "stop" });
  };

  return (
    <Layout.PageLoading>
      <Loading size={75} />
    </Layout.PageLoading>
  );
};

export default Logout;
