import { WaveFormDatum } from "@quantumcast/ui/src/atoms/WaveFormAudio/WaveFormAudio";
import { Bar } from "@visx/shape";
import { HighlightableCue } from "../../../types";

type CuePointProps = {
  dragMove?: () => void;
  dragEnd?: () => void;
  dragStart?: () => void;
  isHighlighted?: boolean;
  dx?: number;
  cuePoint: any;
  height?: number;
  showEveryNthValue: number;
  x: number;
};
const CuePoint = ({
  height = 60,
  isHighlighted = false,
  dragMove = () => {},
  dragEnd = () => {},
  dragStart = () => {},
  x,
  dx = 0,
}: CuePointProps) => {
  const barWidth = 2;
  if (Number.isNaN(x)) {
    return null;
  }

  return (
    <Bar
      y={(height * 0.06) / 2}
      x={x}
      width={barWidth}
      height={height * 0.94}
      fill={isHighlighted ? "var(--brand-secondary)" : "var(--brand-medium)"}
      stroke={isHighlighted ? "var(--brand-secondary)" : "var(--brand-medium)"}
      rx={2}
      ry={2}
      // style={{ cursor: 'col-resize' }}
      transform={`translate(${dx}, 0)`}
      onMouseMove={dragMove}
      onMouseUp={dragEnd}
      onMouseDown={dragStart}
      onTouchStart={dragStart}
      onTouchMove={dragMove}
      onTouchEnd={dragEnd}
    />
  );
};

type CuesProps = {
  cues: HighlightableCue[];
  height: number;
  data: WaveFormDatum[];
  uniqueName: string;
  duration: number;
  showEveryNthValue: number;
};

const Cues = ({ cues, uniqueName, ...props }: CuesProps) => {
  return (
    <>
      {cues.map((cuePoint, _, inputArray) => {
        const x =
          props.data?.findIndex((point) => point.timestamp >= cuePoint.value) *
          props.showEveryNthValue;
        return (
          <CuePoint
            key={`${uniqueName}_${cuePoint.id}`}
            cuePoint={cuePoint}
            isHighlighted={
              !inputArray.some((point) => point.isHighlighted) ||
              cuePoint.isHighlighted
            }
            // dragMove={dragMove}
            // dragEnd={augmentedDragEnd}
            // dragStart={dragStart}
            x={x}
            // y={y}
            // dx={dx}
            // dy={dy}
            {...props}
          />
        );
      })}
    </>
  );
};

export { Cues };
