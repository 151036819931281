import { t } from "../../../global/Localization/translation";
import { getBrowserLocale } from "../../../utils/browser";
import "./FileListMeta.scss";

interface Props {
  length: number;
  selection: number;
  total: number;
}

const FileListMeta = (props: Props) => {
  return (
    <div className="file-list-meta">
      {t("files.list.length", {
        length: new Intl.NumberFormat(getBrowserLocale()).format(props.length),
        total: new Intl.NumberFormat(getBrowserLocale()).format(props.total),
        selection: new Intl.NumberFormat(getBrowserLocale()).format(props.selection),
        count: props.selection,
      })}
    </div>
  );
};

export default FileListMeta;
