import { Button, Code, Modal } from "@quantumcast/ui";
import { t } from "../../../global/Localization/translation";
import "./SearchHelp.scss";

type Props = {
  isOpen: boolean;
  onToggle: () => void;
};

const CASES = [
  {
    code: "meta.sound:slow",
    help: t("help.search.cases.meta"),
  },
  {
    code: "core.fileName:Elvis",
    help: t("help.search.cases.core"),
  },
];

const SearchHelp = (props: Props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onToggle}
      title={t("help.search.headline")}
      actions={
        <>
          <Button onClick={props.onToggle}>{t("action.ok")}</Button>
        </>
      }
    >
      <div className="search-help">
        <p>{t("help.search.intro")}</p>
        <div className="search-help__cases">
          {CASES.map((it) => (
            <div key={it.code} className="search-help__case">
              <div className="search-help__code">
                <Code>{it.code}</Code>
              </div>
              <div className="search-help__help">{it.help}</div>
            </div>
          ))}
        </div>
        <p>{t("help.search.hint")}</p>
      </div>
    </Modal>
  );
};

export default SearchHelp;
