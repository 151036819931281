import { ModalTransition } from "@atlaskit/modal-dialog";
import {
  AudioPlayerContext,
  Button,
  Headline,
  Icon,
  Select,
  WaveFormAudio,
} from "@quantumcast/ui";
import { inspect } from "@xstate/inspect";
import { useActor, useSelector } from "@xstate/react";
import { useContext, useEffect } from "react";
import { t } from "../../../../global/Localization/translation";
import { RawCue } from "../../types";
import { CueList } from "./CueList";
import { CueModal } from "./CueModal";
import "./Cues.scss";
import { CueStateContext, CueStateProvider } from "./CueStateContext";
import { ProgressIndicator } from "./Player/ProgressIndicator";
import { getFirstMatchingIndex } from "./Player/utils";

import { CueKeyModal } from "./CueKeyModal";
import { selectors as cueMachineSelectors } from "./cueMachine";
import { Cues as CuePoints } from "./Player/Cues";
import { DeleteCueKeyModal } from "./modals/DeleteCueKeyModal";
import { SAMPLES_TO_WIDTH_FACTOR } from "./const";
import ConfigContext from "../../../Config/ConfigContext";

const DEBUG_XSTATE = false;
export interface ICuesProps {
  fileId: string;
  cues: RawCue[] | [];
  fileUrl: string;
  waveformDataUrl: string;
  duration: number;
}
if (DEBUG_XSTATE) {
  inspect({
    iframe: false,
  });
}

const {
  selectId,
  selectCues,
  selectCurrentTime,
  selectData,
  selectDuration,
  selectGroupedCues,
  selectSelectedGroup,
} = cueMachineSelectors;

const height = 60;

const SimpleWaveform = () => {
  const { cueService } = useContext(CueStateContext);
  const { playerService, selectors } = useContext(AudioPlayerContext.Context);

  const data = useSelector(cueService, selectData);
  const cues = useSelector(cueService, selectCues);
  const duration = useSelector(cueService, selectDuration);
  const showEveryNthValue = SAMPLES_TO_WIDTH_FACTOR;

  const currentTime = useSelector(playerService, selectCurrentTime);
  const playingFileId = useSelector(playerService, selectors.selectId);
  const cuesFileId = useSelector(cueService, selectId);

  return (
    <WaveFormAudio
      uniqueName="detailData"
      data={data}
      width={920}
      height={height}
      events
    >
      <CuePoints
        uniqueName="detailData"
        duration={duration}
        cues={cues}
        height={height}
        data={data}
        showEveryNthValue={showEveryNthValue}
      />

      {playingFileId === cuesFileId && (
        <ProgressIndicator
          progressX={
            getFirstMatchingIndex(data, currentTime) * showEveryNthValue
          }
          height={height}
        />
      )}
    </WaveFormAudio>
  );
};

const SelectKeys = ({ cues }) => {
  const { cueService } = useContext(CueStateContext);
  const groupedCues = useSelector(cueService, selectGroupedCues);
  const selectedGroup = useSelector(cueService, selectSelectedGroup);

  // in case the cues get updated (eg. when a new group was created) the cues
  // in the state machine need to be updated as well
  useEffect(() => {
    cueService.send({ type: "updateCues", cues });
  }, [cues, cueService]);

  return (
    <Select
      name="cueKeys"
      value={{ value: selectedGroup, label: selectedGroup }}
      options={Object.keys(groupedCues).map((cueKey) => ({
        value: cueKey,
        label: cueKey,
      }))}
      // @ts-expect-error
      onChange={cueService.send}
    />
  );
};

const EditCueModalButton = () => {
  const { cueService } = useContext(CueStateContext);
  const selectedGroup = useSelector(cueService, selectSelectedGroup);

  return (
    <Button
      appearance="link"
      onClick={() => cueService.send({ type: "open" })}
      className="cues__add"
    >
      <Icon.IonIcons5.IoPencil size={12} />
      {t("file.cues.edit", { selectedGroup })}
    </Button>
  );
};

const AddCueKeyModalButton = () => {
  const { addCueKeyModalService } = useContext(CueStateContext);

  return (
    <Button
      appearance="link"
      onClick={() => addCueKeyModalService.send({ type: "open" })}
      className="cues__addKey"
    >
      <Icon.IonIcons5.IoAdd size={12} />
      {t("file.cues.addKey")}
    </Button>
  );
};

const CueKeyAction = () => {
  const { cueService, deleteCueKeyModalService } = useContext(CueStateContext);
  const selectedGroup = useSelector(cueService, selectSelectedGroup);
  const canDelete = selectedGroup !== "default";
  return (
    <div className="cues__action">
      {canDelete ? (
        <div className="cues__delete">
          <Icon.IonIcons5.IoTrashBinSharp
            title={t("file.cues.delete")}
            onClick={() =>
              deleteCueKeyModalService.send({
                type: "open",
                key: selectedGroup,
              })
            }
          />
        </div>
      ) : (
        <div className="cues__delete cues__delete--forbidden">
          <Icon.IonIcons5.IoTrashBinSharp
            title={t("file.cues.noDelete", { selectedGroup })}
          />
        </div>
      )}
    </div>
  );
};

const AddCueKeyModalWrapper = () => {
  const { addCueKeyModalService } = useContext(CueStateContext);
  const [state] = useActor(addCueKeyModalService);
  return state.matches("open") && <CueKeyModal />;
};
const DeleteCueKeyModalWrapper = () => {
  const { deleteCueKeyModalService } = useContext(CueStateContext);
  const [state] = useActor(deleteCueKeyModalService);
  return (
    (state.matches("open") || state.matches("deleting")) && (
      <DeleteCueKeyModal />
    )
  );
};

const EditCueModalWrapper = () => {
  const { cueService } = useContext(CueStateContext);
  const isInModalOpenState = useSelector(cueService, (state) => {
    // return state.matches('loaded.modal.open')})
    return state.matches({ loaded: { modal: "open" } });
  });

  return (
    isInModalOpenState && (
      <CueModal
        onClose={() => {
          cueService.send({ type: "close" });
        }}
      />
    )
  );
};
const Cues = (props: ICuesProps) => {
  const config = useContext(ConfigContext);

  return (
    <div className="cues">
      <CueStateProvider libraryId={config.library} {...props}>
        <Headline level={3}>{t("file.cues.title")}</Headline>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 30px",
            gridTemplateAreas: `"leftColumn rightColumn actions"`,
          }}
        >
          <SelectKeys cues={props.cues} />

          <div>
            <AddCueKeyModalButton />
          </div>
          <CueKeyAction />
        </div>

        <div className="cues__info">
          <p>{t("file.cues.info")}</p>
        </div>
        <div
          style={{ background: "rgb(250,251,252)", color: "var(--brand-dark)" }}
        >
          <SimpleWaveform />
        </div>
        <CueList fileId={props.fileId} isReadOnly uniqueName="overview" />
        <EditCueModalButton />

        <ModalTransition>
          <DeleteCueKeyModalWrapper />
          <EditCueModalWrapper />
          <AddCueKeyModalWrapper />
        </ModalTransition>
      </CueStateProvider>
    </div>
  );
};

export default Cues;
