import { Icon } from "@quantumcast/ui";
import { Link } from "react-router-dom";
import { t } from "../../../../global/Localization/translation";
import type { Playlist } from "../../domain";
import "./PlaylistListItem.scss";

interface Props {
  library: string;
  playlist: Playlist;
  onDelete: (playlist: Playlist) => void;
}

const PlaylistListItem = (props: Props) => {
  const onDelete = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    props.onDelete(props.playlist);
  };

  return (
    <Link
      to={`/playlists/${props.library}/${props.playlist.id}`}
      className="playlist-list-item"
    >
      <div className="playlist-list-item__header">
        <div className="playlist-list-item__data">
          <div className="playlist-list-item__name">{props.playlist.name}</div>
          <div
            className="playlist-list-item__type"
            title={t("playlists.types.description", {
              context: props.playlist.type,
            })}
          >
            {t("playlists.types.name", { context: props.playlist.type })}
          </div>
        </div>
        <div className="playlist-list-item__delete" onClick={onDelete}>
          <Icon.IonIcons5.IoTrashBinSharp
            title={t("playlist.delete.title")}
            color="gray"
          />
        </div>
      </div>
    </Link>
  );
};

export default PlaylistListItem;
