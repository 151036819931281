import { Layout, Link, TopNavigation } from "@quantumcast/ui";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { t } from "../../../global/Localization/translation";
import Logo from "../../atoms/Logo/Logo";

interface Props {
  children?: React.ReactNode;
  width?: "full" | "large" | "narrow";
}

const Page = (props: Props) => {
  return (
    <Layout.Page
      width={props.width}
      header={
        <TopNavigation
          logo={
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          }
          main={
            <>
              <Link to="/">{t("navigation.files")}</Link>
              <Link to="/playlists">{t("navigation.playlists")}</Link>
            </>
          }
          aside={<Link to="/logout">{t("navigation.logout")}</Link>}
        />
      }
    >
      {props.children}
    </Layout.Page>
  );
};

export default Page;
