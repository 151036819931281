import ReactDOM from "react-dom";
import { Normalize } from "@quantumcast/ui";
import Contexts from "./global/Contexts/Contexts";
import Router from "./global/Navigation/Router";
import Routes from "./global/Navigation/Routes";
import "./global/Styles/index.scss";

if(process.env.NODE_ENV === "development") {
  require('./mocks/browser')
}

ReactDOM.render(
  <Router>
    <Contexts>
      <Normalize>
        <Routes />
      </Normalize>
    </Contexts>
  </Router>,
  document.getElementById("root")
);
