import { yupResolver } from "@hookform/resolvers/yup";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import {
  Banner,
  Button,
  Headline,
  Input,
  Form,
  FormActions,
  FormRow,
  Layout,
  Text,
} from "@quantumcast/ui";
import { t } from "../Localization/translation";
import AuthContext from "./AuthContext";
import { OnResetPassword } from "./types";

type FormData = {
  password: string;
  passwordRepeat: string;
};

const ResetPassword = () => {
  const { token } = useParams<{ token: string | undefined }>();
  const auth = React.useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const schema = React.useMemo(
    () =>
      yup.object().shape({
        password: yup
          .string()
          .min(16, t("error.password.min"))
          .max(50, t("error.password.max"))
          .required(t("error.password.required")),
        passwordRepeat: yup
          .string()
          .min(16, t("error.password.min"))
          .max(50, t("error.password.max"))
          .oneOf([yup.ref("password"), null], t("error.password.match"))
          .required(t("error.password.required")),
      }),
    []
  );
  const { register, errors, handleSubmit } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormData) => {
    if (!token) return;
    setIsLoading(true);
    setError(null);
    const error: OnResetPassword = await auth
      .onResetPassword(token, data.password)
      .catch((r) => r);
    setIsLoading(false);
    if (error) {
      setError(t(`error.http.${error.type}`));
    }
  };

  return (
    <Layout.Login>
      <Headline level={1}>{t("resetPassword.headline")}</Headline>
      <Text>{t("resetPassword.passwordRequirements")}</Text>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {error && (
          <FormRow>
            <Banner appearance="error">{error}</Banner>
          </FormRow>
        )}
        <FormRow>
          <Input
            ref={register}
            name="password"
            type="password"
            placeholder={t("resetPassword.password")}
            label={t("resetPassword.password")}
            error={errors.password && errors.password.message}
            isInvalid={Boolean(errors.password)}
          />
        </FormRow>
        <FormRow>
          <Input
            ref={register}
            name="passwordRepeat"
            type="password"
            placeholder={t("resetPassword.passwordRepeat")}
            label={t("resetPassword.passwordRepeat")}
            error={errors.passwordRepeat && errors.passwordRepeat.message}
            isInvalid={Boolean(errors.passwordRepeat)}
          />
        </FormRow>
        <FormActions
          right={
            <Button type="submit" isLoading={isLoading}>
              {t("resetPassword.submit")}
            </Button>
          }
        />
      </Form>
    </Layout.Login>
  );
};

export default ResetPassword;
