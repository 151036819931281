import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export function useSearchParams<T>(): T {
  const { search } = useLocation();

  return useMemo(
    () => Object.fromEntries(new URLSearchParams(search)) as T,
    [search]
  );
}
