const Logo = () => {
  return (
    <img
      src="/favicon.png"
      width="25px"
      alt="Logo"
      style={{ display: "block" }}
    />
  );
};

export default Logo;
