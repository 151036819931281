import { useInterpret } from "@xstate/react";
import { createContext, useContext } from "react";
import { InterpreterFrom } from "xstate";
import PlaylistsContext from "../../../PlaylistsContext";
import { deletePlaylistsMachine } from "./deletePlaylistsMachine";

export const DeletePlaylistContext = createContext({
  deletePlaylistsService: {} as InterpreterFrom<typeof deletePlaylistsMachine>,
});

export const DeletePlaylistProvider = ({ children }) => {
  const playlists = useContext(PlaylistsContext);
  const deletePlaylistsService = useInterpret(deletePlaylistsMachine, {
    services: {
      deletePlaylist: async (context) => {
        return await playlists.onDelete(context.playlist);
      },
    },
  });

  return (
    <DeletePlaylistContext.Provider value={{ deletePlaylistsService }}>
      {children}
    </DeletePlaylistContext.Provider>
  );
};
