import { Headline } from "@quantumcast/ui";
import { useMachine } from "@xstate/react";
import React from "react";
import { t } from "../../../../global/Localization/translation";
import FileContext from "../../FilesContext";
import { RawFileMetaDatum } from "../../types";
import AddMetaData from "./AddMetaData/AddMetaData";
import { addMetaDataMachine } from "./AddMetaData/metaDataMachine";
import "./MetaData.scss";
import MetaDatum from "./MetaDatum/MetaDatum";
export interface Props {
  fileId: string;
  metaData: RawFileMetaDatum[];
}

const MetaData = (props: Props) => {
  const files = React.useContext(FileContext);

  // eslint-disable-next-line
  const [_, send] = useMachine(addMetaDataMachine, {
    context: {
      files,
      fileId: props.fileId,
    },
    services: {
      addMetaDatum: (context) =>
        context.files.onAddMetaDatum(context.fileId, context.datum),
    },
  });

  return (
    <div className="meta-data">
      <Headline level={3}>{t("file.metaData.title")}</Headline>
      <div className="meta-data__list">
        {props.metaData.map((datum) => (
          <MetaDatum
            key={datum.id}
            fileId={props.fileId}
            datum={datum}
            intent="update"
            onChange={(data) =>
              send({ type: "validateMetaDatum", payload: data })
            }
          />
        ))}
      </div>
      <AddMetaData fileId={props.fileId} />
    </div>
  );
};

export default MetaData;
