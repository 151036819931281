import { useInterpret } from '@xstate/react';
import { createContext, useContext } from 'react';
import { InterpreterFrom } from 'xstate';
import FileContext from '../../FilesContext';
import { addCueKeyModalMachine } from './addCueKeyModalMachine';
import { cueMachine } from './cueMachine';
import { deleteCueKeyModalMachine } from './modals/machines/deleteCueKeyModalMachine';

export const CueStateContext = createContext({
  cueService: {} as InterpreterFrom<typeof cueMachine>,
  addCueKeyModalService: {} as InterpreterFrom<typeof addCueKeyModalMachine>,
  deleteCueKeyModalService: {} as InterpreterFrom<typeof deleteCueKeyModalMachine>
});

export const CueStateProvider = ({ children, ...props }) => {
  const files = useContext(FileContext);
  const addCueKeyModalService = useInterpret(addCueKeyModalMachine, {
    devTools: true,
    services: {
      // @ts-expect-error
      addCueGroup: async (context) => {
        return await files.onAddCue(props.fileId, context.value?.value)
      }
    }
  })

  const cueService = useInterpret(cueMachine, {
    devTools: true,
    context: props,
    services: {
      // @ts-expect-error
      onUpdateCues: async (context) => {
        return await files.onUpdateCues(props.fileId, Object.values(context.groupedCues).flat())
      }
    }
  });

  const deleteCueKeyModalService = useInterpret(deleteCueKeyModalMachine, {
    devTools: true,
    services: {
      deleteCueKey: async (context) => {
        return await files.onDeleteCueKey(props.fileId, context.key)
      }
    }
  })


  return (
    <CueStateContext.Provider value={{ cueService, addCueKeyModalService, deleteCueKeyModalService }}>
      {children}
    </CueStateContext.Provider>
  );
};