import { useState } from "react";

export const useToggle = (initial?: boolean) => {
  const [isTrue, setState] = useState(initial || false);

  const onToggle = () => {
    setState(!isTrue);
  };

  return { isTrue, onToggle };
};
