import * as React from "react";
import { useLocalStorage } from "@klw/localstorage";

import STORAGE_NAMES from "../../utils/storageNames";
import AuthContext from "../Auth/AuthContext";
import Login from "../Auth/Login";
import { AudioPlayer, Layout, Loading } from "@quantumcast/ui";

interface Props {
  children?: React.ReactNode;
}

const PrivateRoute = (props: Props) => {
  const storage = useLocalStorage();
  const auth = React.useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const token = storage.get(STORAGE_NAMES.TOKEN);
    if (token) {
      setIsLoading(true);
      auth.onCheckToken(token).then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  if (auth.isAuthenticated) {
    return (
      <>
        {props.children}
        <AudioPlayer.Global />
      </>
    );
  }

  return (
    <>
      {isLoading ? (
        <Layout.PageLoading>
          <Loading size={75} />
        </Layout.PageLoading>
      ) : (
        <Login />
      )}
    </>
  );
};

export default PrivateRoute;
