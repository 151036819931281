import { get, del, setParams, put, post, postFile, patch } from "@klw/fetch";
import { HttpError } from "@klw/fetch/lib/types";
import { Option } from "@quantumcast/ui";
import * as React from "react";
import AuthContext from "../../global/Auth/AuthContext";
import { DEFAULT_LIBRARY } from "../../global/Auth/const";
import { useSearchParams } from "../../utils/useSearchParams";
import ConfigContext from "../Config/ConfigContext";
import { ORDER_BY } from "./const";
import { File } from "./domain";
import { FileSelectionAction, fileSelectionReducer } from "./reducers";
import {
  FileSelection,
  MetaDatumModification,
  RawCue,
  RawFile,
  RawFileMetaDatum,
} from "./types";

interface IFileContext {
  isInitialized: boolean;
  isLoading: boolean;
  total: number;
  length: number;
  search: string;
  order: Option;
  list: File[];
  selection: FileSelection;
  onAddCue: (fileId: string, key: string) => Promise<void | HttpError>;
  onAddMetaDatum: (
    fileId: string,
    datum: Omit<RawFileMetaDatum, "id">
  ) => Promise<void | HttpError>;
  onChangeLibrary: (library: string) => void;
  onChangeOrder: (order: Option) => void;
  onChangeSearch: (search: string) => void;
  onLoad: () => Promise<void>;
  onDelete: (file: File) => Promise<void>;
  onDeleteCue: (fileId: string, cueId: string) => Promise<void | HttpError>;
  onDeleteCueKey: (fileId: string, key: string) => Promise<void | HttpError>;
  onDeleteMetaDatum: (
    fileId: string,
    metaDatumId: string
  ) => Promise<void | HttpError>;
  onReset: () => void;
  onUpdateCues: (fileId: string, cue: RawCue[]) => Promise<void | HttpError>;
  onUpdateMetaDatum: (
    fileId: string,
    datum: RawFileMetaDatum
  ) => Promise<void | HttpError>;
  onBatchUpdateMetaData: (
    fileIds: string[],
    ops: MetaDatumModification[]
  ) => Promise<void | HttpError>;
  onUpdateSelection: (action: FileSelectionAction) => void;
  onUpload: (file: any, progress: (progress: number) => void) => Promise<void>;
}

interface IFileContextProvider {
  children?: React.ReactNode;
}

const defaultContext = {
  isInitialized: false,
  isLoading: false,
  search: "",
  order: ORDER_BY[0],
  total: 0,
  list: [] as File[],
  selection: { selected: [] } as FileSelection,
};

const FileContext = React.createContext<IFileContext>(
  defaultContext as IFileContext
);

export const FileContextProvider = (props: IFileContextProvider) => {
  const auth = React.useContext(AuthContext);
  const { libraries } = auth;
  const config = React.useContext(ConfigContext);
  const library = config.library;
  const [isInitialized, setIsInitialized] = React.useState(
    defaultContext.isInitialized
  );
  const [isLoading, setIsLoading] = React.useState(defaultContext.isLoading);
  const params = useSearchParams<{ search?: string }>();
  const [search, setSearch] = React.useState(
    params.search || defaultContext.search
  );
  const [order, setOrder] = React.useState(defaultContext.order);
  const [total, setTotal] = React.useState(defaultContext.total);
  const [list, setList] = React.useState(defaultContext.list);
  const [selection, dispatchSelection] = React.useReducer(
    fileSelectionReducer,
    defaultContext.selection
  );

  React.useEffect(() => {
    if (libraries.length > 0 && library === DEFAULT_LIBRARY) {
      config.onLoadConfig(libraries[0]);
      setIsInitialized(true);
      config.onLoadConfig(libraries[0]);
    }
  }, [libraries, library, config]);

  React.useEffect(() => {
    if (search) {
      const timeoutId = setTimeout(() => onLoad(), 500);
      return () => clearTimeout(timeoutId);
    } else {
      onLoad();
    }

    // eslint-disable-next-line
  }, [library, search, order]);

  React.useEffect(() => {
    if (params.search !== search) {
      setIsLoading(true);
      setList([]);
      setSearch(params.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const onAddCue = async (fileId: string, key: string) => {
    const resp = await post(`/api/libraries/${library}/files/${fileId}/cues`, {
      key,
    });

    if (!resp.ok) {
      return resp.error;
    } else {
      await onRefetchConfigIfRequired("cue", resp.body[0]);
      await onRefetchFile(fileId);
    }
  };

  const onAddMetaDatum = async (
    fileId: string,
    datum: Omit<RawFileMetaDatum, "id">
  ) => {
    const resp = await post(
      `/api/libraries/${library}/files/${fileId}/metaData`,
      datum
    );
    if (!resp.ok) {
      return resp.error;
    } else {
      await onRefetchFile(fileId);
    }
  };

  const onLoad = async () => {
    // Do not load, if not initialized
    if (!isInitialized) {
      return;
    }

    // Not searching, if search string is set but under 3 characters
    if (search && search.length < 3) {
      return;
    }

    setIsLoading(true);
    const uri = setParams(`/api/libraries/${library}/files`, {
      search,
      order: order?.value || ORDER_BY[0].value,
      page: 1,
      perPage: search ? undefined : "250",
    });
    const resp = await get(uri);
    if (resp.ok) {
      const newTotal = resp.body?._metaData.total || 0;
      const newFiles =
        resp.body?.list?.map((file: RawFile) => new File(file)) || [];
      setTotal(newTotal);
      setList(newFiles);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      return;
    }
  };

  const onDelete = async (file: File) => {
    const resp = await del(`/api/libraries/${library}/files/${file.id}`);
    if (resp.ok) {
      const newFiles = list.filter((it) => it.id !== file.id);
      setList(newFiles);
    }
  };
  const onDeleteCueKey = async (fileId: string, key: string) => {
    const resp = await del(`/api/libraries/${library}/files/${fileId}/cues`, {
      key,
    });

    if (!resp.ok) {
      return resp.error;
    } else {
      // TODO: remove fakeCue, this CUE is neccessary to trigger a refetch
      const fakeCue = {
        id: "123",
        key: `${Math.random() * 100}`,
        value: 0,
        type: "CUE_IN",
      };
      // TODO: Refetch config without requiring a cue object
      await onRefetchConfigIfRequired("cue", fakeCue);
      await onRefetchFile(fileId);
    }
  };

  const onDeleteCue = async (fileId: string, cueId: string) => {
    const resp = await del(
      `/api/libraries/${library}/files/${fileId}/cues/${cueId}`
    );
    if (!resp.ok) {
      return resp.error;
    } else {
      await onRefetchFile(fileId);
    }
  };

  const onDeleteMetaDatum = async (fileId: string, metaDatumId: string) => {
    const resp = await del(
      `/api/libraries/${library}/files/${fileId}/metaData/${metaDatumId}`
    );
    if (!resp.ok) {
      return resp.error;
    } else {
      await onRefetchFile(fileId);
    }
  };

  const onRefetchConfigIfRequired = (
    type: string,
    entity: RawCue | RawFileMetaDatum
  ) => {
    // only refetch when a new value was submitted
    if (
      (type === "cue" &&
        !config.cueKeys.map((x) => x.value).includes(entity.key)) ||
      (type === "datum" && !config.metaDataKeys.map((x) => x.value).includes) ||
      (type === "datum" &&
        !config.metaDataTypes.map((x) => x.value).includes(entity.type))
    ) {
      config.onRefetch();
    }
  };

  const onRefetchFile = async (fileId: string) => {
    const resp = await get(`/api/libraries/${library}/files/${fileId}`);
    if (resp.ok) {
      const newFile = new File(resp.body as RawFile);
      const index = list.findIndex((x) => x.id === fileId);
      if (newFile && index > -1) {
        const newList = [...list];
        newList[index] = newFile;
        setList(newList);
      }
      setIsLoading(false);
    }
  };

  const onReset = () => {
    setIsInitialized(defaultContext.isInitialized);
    setIsLoading(defaultContext.isLoading);
    config.onLoadConfig(DEFAULT_LIBRARY);
    setSearch(defaultContext.search);
    setOrder(defaultContext.order);
    setTotal(defaultContext.total);
    setList(defaultContext.list);
    dispatchSelection({
      type: "reset",
    });
  };

  const onUpdateCues = async (fileId: string, cues: RawCue[]) => {
    const resp = await put(
      `/api/libraries/${library}/files/${fileId}/cues`,
      cues
    );
    if (!resp.ok) {
      return resp.error;
    } else {
      onRefetchFile(fileId);
    }
  };

  const onUpdateMetaDatum = async (fileId: string, datum: RawFileMetaDatum) => {
    const resp = await put(
      `/api/libraries/${library}/files/${fileId}/metaData/${datum.id}`,
      datum
    );
    if (!resp.ok) {
      return resp.error;
    } else {
      onRefetchConfigIfRequired("datum", datum);
    }
  };

  const onBatchUpdateMetaData = async (
    fileIds: string[],
    operations: MetaDatumModification[]
  ) => {
    const resp = await patch(`/api/libraries/${library}/files-batch-update`, {
      fileIds,
      operations,
    });
    if (!resp.ok) {
      return resp.error;
    } else {
      onLoad();
    }
  };

  const onUpdateSelection = async (action: FileSelectionAction) => {
    return new Promise((_, __) => {
      dispatchSelection(action);
    });
  };

  const onUpload = async (
    file: File[],
    onProgress: (progress: number) => void
  ) => {
    const resp = await postFile(`/api/libraries/${library}/upload`, file, {
      onProgress,
    });
    if (!resp.ok) {
      console.error("File could not be uploaded", resp.error);
    } else {
      const orderByUploadedAt = ORDER_BY.find(
        (it) => it.value === "uploadedAt"
      );

      // Reset search; order of changes is important here
      orderByUploadedAt && setOrder(orderByUploadedAt);
      setSearch("");
    }
  };

  const state: IFileContext = {
    isInitialized,
    isLoading,
    total,
    length: list.length,
    search,
    order,
    list,
    selection,
    onAddCue,
    onAddMetaDatum,
    onBatchUpdateMetaData,
    onChangeLibrary: config.onLoadConfig,
    onChangeOrder: setOrder,
    onChangeSearch: setSearch,
    onLoad,
    onDelete,
    onDeleteCue,
    onDeleteCueKey,
    onDeleteMetaDatum,
    onReset,
    onUpdateCues,
    onUpdateMetaDatum,
    onUpdateSelection,
    onUpload,
  };

  return (
    <FileContext.Provider value={state}>{props.children}</FileContext.Provider>
  );
};

export default FileContext;
