import { FileUpload } from "../types";

type UploadAction = UploadActionProgress | UploadActionStart | UploadActionStop;
type UploadActionStart = {
  type: "START";
  files: FileUpload[];
};
type UploadActionProgress = {
  type: "PROGRESS";
  id: string;
  progress: number;
};
type UploadActionStop = {
  type: "STOP";
};

export function reducer(state: FileUpload[], action: UploadAction) {
  switch (action.type) {
    case "START":
      return action.files;
    case "STOP":
      return [];
    case "PROGRESS":
      if (action.progress === 100) {
        return state.filter((it) => it.id !== action.id);
      } else {
        const uploadIndex = state.findIndex((it) => it.id === action.id);
        if (uploadIndex > -1) {
          const newState = [...state];
          newState[uploadIndex] = {
            ...newState[uploadIndex],
            progress: action.progress,
          };
          return newState;
        }
        return state;
      }
  }
}
