import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { Banner, Button, Loading } from "@quantumcast/ui";
import React from "react";
import { t } from "../../../../global/Localization/translation";
import FileContext from "../../FilesContext";
import {
  AmbiguousMetaDatum,
  MetaDatumModification,
  RawFileMetaDatum,
} from "../../types";
import { getOverlapMetaData } from "../../utils";
import AddMetaData from "../MetaData/AddMetaData/AddMetaData";
import MetaDatum from "../MetaData/MetaDatum/MetaDatum";
import "./FileEditor.scss";

const FileEditor = () => {
  const files = React.useContext(FileContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [metaData, setMetaData] = React.useState<AmbiguousMetaDatum[]>([]);
  const [modifications, setModifications] = React.useState<
    MetaDatumModification[]
  >([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const length = files.selection.selected.length;
  const maxLength = 100;
  const isSelectionTooLarge = length < 2 || length > maxLength;
  const isSelectionTooSmall = length < 2;

  const onToggleOpen = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      setIsLoading(true);
      setIsError(false);
      setMetaData([]);
      setModifications([]);
    } else {
      const overlaps = getOverlapMetaData(files.list, files.selection.selected);
      setIsLoading(false);
      setMetaData(overlaps);
    }
  };

  const onAdd = (datum: RawFileMetaDatum) => {
    let newMetaData = [...metaData, datum];
    setMetaData(newMetaData);
    addModification({ op: "add", path: datum.key, value: datum });
  };

  const onChange = (datum: RawFileMetaDatum) => {
    let newMetaData = [...metaData];
    const index = newMetaData.findIndex((it) => it.key === datum.key);
    if (index > -1) {
      newMetaData[index] = datum;
      setMetaData(newMetaData);
      addModification({ op: "replace", path: datum.key, value: datum });
    }
  };

  const onDelete = (key: string) => {
    let newMetaData = [...metaData];
    const index = newMetaData.findIndex((it) => it.key === key);
    if (index > -1) {
      newMetaData.splice(index, 1);
      setMetaData(newMetaData);
      addModification({ op: "remove", path: key });
    }
  };

  const addModification = (mod: MetaDatumModification) => {
    setModifications([...modifications, mod]);
  };

  const onSave = async () => {
    setIsError(false);
    setIsSaving(true);
    const error = await files.onBatchUpdateMetaData(files.selection.selected, modifications);
    setIsSaving(false);
    if (error) {
      setIsError(true);
    } else {
      onToggleOpen();
    }
  };

  let editOption = (
    <Button onClick={onToggleOpen}>{t("file.editor.open")}</Button>
  );
  if (isSelectionTooLarge) {
    editOption = <>{t("file.editor.tooManyFiles", { length: maxLength })}</>;
  }
  if (isSelectionTooSmall) {
    editOption = null;
  }

  const keysAlreadyInUse = metaData.map((it) => it.key);

  return (
    <>
      {Boolean(editOption) && (
        <div className="file-editor-button">{editOption}</div>
      )}
      <ModalTransition>
        {isOpen && (
          <Modal width="large" onClose={onToggleOpen}>
            <ModalHeader>
              <ModalTitle>{t("file.editor.title", { length })}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              {isError && (
                <div className="file-editor__error">
                  <Banner appearance="error">{t("file.editor.error")}</Banner>
                </div>
              )}
              <div className="file-editor">
                {isLoading ? (
                  <div className="file-editor__loader">
                    <Loading size={32} />
                  </div>
                ) : (
                  <>
                    {metaData.map((datum) => (
                      <MetaDatum
                        key={datum.key}
                        datum={datum}
                        intent="multi-update"
                        onChange={onChange}
                        onDelete={onDelete}
                      />
                    ))}
                  </>
                )}
              </div>
              <AddMetaData
                saveToMemory={onAdd}
                disabledKeys={keysAlreadyInUse}
              />
            </ModalBody>
            <ModalFooter>
              <div className="file-editor-info">
                {t("file.editor.info")}
              </div>
              <Button onClick={onToggleOpen} appearance="secondary">
                {t("action.cancel")}
              </Button>
              <Button
                onClick={onSave}
                isDisabled={isLoading || modifications.length === 0}
                isLoading={isSaving}
              >
                {t("action.save")}
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default FileEditor;
