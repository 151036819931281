import {
  Banner,
  Button,
  EmptyState,
  Headline,
  Layout,
  Loading,
  Time
} from "@quantumcast/ui";
import { useMachine } from "@xstate/react";
import * as React from "react";
import { t } from "../../../../global/Localization/translation";
import { Playlist } from "../../domain";
import PlaylistsContext from "../../PlaylistsContext";
import PlaylistFile from "./PlaylistFile/PlaylistFile";
import "./PlaylistPreview.scss";
import { playlistPreviewMachine } from "./playlistPreviewMachine";
import { useState } from "react";
import { File } from "../../../Files/domain";
import {updatePlaylistMachine} from "./updatePlaylistMachine";
import {PLAYLIST_TYPE_STATIC} from "../PlaylistSettings/PlaylistSettings";

type Props = {
  playlist: Playlist;
  isActivePlaylist: boolean | undefined;
};

const PlaylistPreview = (props: Props) => {
  const id = props.playlist.id;
  const playlists = React.useContext(PlaylistsContext);
  const [state, sendEvent] = useMachine(playlistPreviewMachine, {
    context: {
      id,
    },
    services: {
      loadPreview: (context) => playlists.onLoadPreview(context.id),
    },
  });
  const [updatePlaylistMachineState, sendUpdatePlaylistMachineStateEvent] = useMachine(updatePlaylistMachine, {
    services: {
      updatePlaylist: (context) => playlists.onSave(id, context.playlist as Playlist),
    },
  });

  const previewButton = (
    <Button onClick={() => sendEvent({ type: "LOAD_PREVIEW" })}>
      {t("playlist.detail.empty.generate")}
    </Button>
  );

  const updateManualPlaylist = async () => {
    const currentPlaylist = playlists.list.filter((playlist: Playlist) => { return playlist.id === id})
    if (!currentPlaylist.length) {
      return
    }

    const playlist = new Playlist(currentPlaylist[0])
    playlist.type = PLAYLIST_TYPE_STATIC
    playlist.filesIDs = state.context.preview.files.map((file: File) => { return file.id})

    sendUpdatePlaylistMachineStateEvent(({ type: "UPDATE_PLAYLIST", playlist: playlist }))
  }

  const saveManualPlaylistButton = (
    <Button onClick={() => updateManualPlaylist()}>
      {t("playlist.detail.save.title")}
    </Button>
  );

  const [isActivePlaylist, setIsActivePlaylist] = useState<boolean>(props.playlist.type === PLAYLIST_TYPE_STATIC);

  // setFiles is used to re-render the view
  const setFiles = useState<File[]>([])[1];
  const dragItem = React.useRef<any>(null);
  const [isDragging, setIsDragging] = React.useState(false);
  const [dragOverItem, setDragOverItem] = React.useState<number>(-1);

  const handleDrop = () => {
    let _files = [...state.context.preview.files];

    // Remove and save the dragged item content
    const draggedItemContent = _files.splice(dragItem.current, 1)[0];

    // Switch the position
    _files.splice(dragOverItem, 0, draggedItemContent);

    dragItem.current = null;
    setDragOverItem(-1);

    state.context.preview.files = _files
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(dragOverItem !== dragItem.current)
  }

  const onDelete = (index: Number) => {
    let _files = [...state.context.preview.files];
    _files.splice(Number(index), 1);
    setFiles(_files)
    state.context.preview.files = _files
  }

  const showSaveButton = () => {
    return isActivePlaylist
  }

  React.useEffect(() => {
    if (props.isActivePlaylist !== undefined) {
      setIsActivePlaylist(props.isActivePlaylist)
    }
  }, [props.isActivePlaylist]);

  if (state.matches("LOADED")  ) {
    return (
      <div className="playlist-preview">
        <>
          <Headline level={1} gutterBottom={false}>
            {state.context.preview.name}
          </Headline>
          <div className="playlist-preview__header">
            <div className="playlist-preview__meta-data">
              <div className="playlist-preview__meta-datum">
                <div>{t("playlist.detail.preview.playtime")}</div>
                <Time time={state.context.preview.playtime} />
              </div>
              <div className="playlist-preview__meta-datum">
                <div>{t("playlist.detail.preview.cues")}</div>
                {state.context.preview.cues}
              </div>
            </div>

            {(updatePlaylistMachineState.matches("UPDATE")) &&  (
              <Layout.SectionLoading appearance="small">
                <Loading size={20} />
              </Layout.SectionLoading>
            )}
            {showSaveButton() && (
              <div className="playlist-preview__actions playlist-preview__persist">{saveManualPlaylistButton}</div>
            )}
            <div className="playlist-preview__actions">{previewButton}</div>
          </div>
          {updatePlaylistMachineState.context.success && (
            <Banner appearance="success">{t(updatePlaylistMachineState.context.success)}</Banner>
          )}
          {updatePlaylistMachineState.context.error && (
            <div className="delete-playlist__error">
              <Banner appearance="error">{t(updatePlaylistMachineState.context.error)}</Banner>
            </div>
          )}
          <div className="playlist-preview__list">
            {!isActivePlaylist && state.context.preview.files.map((item, index) => (
              <PlaylistFile
                key={index}
                file={item}
                playlistCue={state.context.preview.cues}
                isActive={false}
              />
            ))}
            {isActivePlaylist && state.context.preview.files.map((item, index) => (
              <div
                draggable
                key={index}
                onDragStart={() => (dragItem.current = index)}
                onDragEnter={() => (setDragOverItem(index))}
                onDragEnd={() => (setIsDragging(false))}
                onDragOver={(e ) => onDragOver(e)}
                onDrop={handleDrop}
                className={isDragging && dragOverItem === index ? "playlist-preview__dropArea playlist-preview__draggable" : "playlist-preview__draggable"}
              >
                <PlaylistFile
                  key={index}
                  index={index}
                  file={item}
                  playlistCue={state.context.preview.cues}
                  isActive={true}
                  onDelete={(i: Number) => onDelete(i)}
                />
              </div>
            ))}
          </div>
        </>
      </div>
    );
  } else if (state.matches("LOADING")) {
    return (
      <Layout.SectionLoading>
        <Loading size={75} />
      </Layout.SectionLoading>
    );
  } else {
    return (
      <>
        {state.context.error && (
          <Banner appearance="error">{t(state.context.error)}</Banner>
        )}
        <EmptyState
          headline={t("playlist.detail.empty.title")}
          text={t("playlist.detail.empty.text")}
          actions={previewButton}
        />
      </>
    );
  }
};

export default PlaylistPreview;
