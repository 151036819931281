export const getBrowserLocale = () =>
  // @ts-ignore
  navigator.userLanguage ||
  (navigator.languages &&
    navigator.languages.length &&
    navigator.languages[0]) ||
  navigator.language ||
  // @ts-ignore
  navigator.browserLanguage ||
  // @ts-ignore
  navigator.systemLanguage ||
  "en";
