import { Icon, Input, Option, Select } from "@quantumcast/ui";
import React from "react";
import { t } from "../../../../../global/Localization/translation";
import { PlaylistRuleRestriction } from "../../../types";

import "./PlaylistRestriction.scss";

const UNITS: Option[] = [
  {
    label: t("playlist.settings.rules.restriction.minutes"),
    value: "MINUTES",
  },
  {
    label: t("playlist.settings.rules.restriction.hours"),
    value: "HOURS",
  },
  {
    label: t("playlist.settings.rules.restriction.items"),
    value: "ITEMS",
  },
];

type PropsAdd = {
  type: "add";
  restriction: Partial<PlaylistRuleRestriction>;
  onChange: (item: Partial<PlaylistRuleRestriction>) => void;
};

type PropsEdit = {
  type: "edit";
  index: number;
  restriction: PlaylistRuleRestriction;
  onChange: (index: number, item: PlaylistRuleRestriction) => void;
  onDelete: (index: number) => void;
};

type Props = (PropsAdd | PropsEdit) & {
  metaDataKeys: Option[];
};

const PlaylistRestriction = (props: Props) => {
  const toOption = (it: string) => ({ label: it, value: it });

  const metaDatumKey: Option = props.restriction?.metaDatumKey
    ? toOption(props.restriction.metaDatumKey)
    : undefined;
  const [limit, setLimit] = React.useState<number>(
    props.restriction?.limit ?? 1
  );
  const [factor, setFactor] = React.useState<number>(
    props.restriction?.factor ?? 1
  );
  const unit: Option = UNITS.find((it) => it.value === props.restriction.unit);

  const onBlur = () => {
    if (props.type === "edit") {
      props.onChange(props.index, {
        ...props.restriction,
        limit,
        factor,
      });
    }
    if (props.type === "add") {
      props.onChange({
        ...props.restriction,
        limit,
        factor,
      });
    }
  };

  const onChangeKey = (_: string, option: Option) => {
    if (props.type === "edit") {
      props.onChange(props.index, {
        ...props.restriction,
        limit,
        factor,
        metaDatumKey: option.value,
      });
    }
    if (props.type === "add") {
      props.onChange({
        ...props.restriction,
        limit,
        factor,
        metaDatumKey: option.value,
      });
    }
  };

  const onChangeLimit = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.value) {
      setLimit(parseInt(e.currentTarget.value));
    }
  };

  const onChangeFactor = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.value) {
      setFactor(parseInt(e.currentTarget.value));
    }
  };

  const onChangeUnit = (_: string, option: Option) => {
    // TODO: Improve typing
    type UnitType = "HOURS" | "MINUTES" | "ITEMS";
    if (props.type === "edit") {
      props.onChange(props.index, {
        ...props.restriction,
        limit,
        factor,
        unit: option.value as UnitType,
      });
    }
    if (props.type === "add") {
      props.onChange({
        ...props.restriction,
        limit,
        factor,
        unit: option.value as UnitType,
      });
    }
  };

  const onDelete = () => {
    props.type === "edit" && props.onDelete(props.index);
  };

  const classNames = [
    "playlist-restriction",
    `playlist-restriction--${props.type}`,
  ];

  return (
    <div className={classNames.join(" ")}>
      <Select
        name="metaDatumKey"
        placeholder={t("playlist.settings.rules.restriction.metaDatum")}
        label={
          <div className="playlist-restriction__label">
            {t("playlist.settings.rules.restriction.metaDatum")}
          </div>
        }
        options={props.metaDataKeys}
        value={metaDatumKey ?? undefined}
        onChange={onChangeKey}
      />
      {props.type === "edit" && (
        <div className="playlist-restriction__delete" onClick={onDelete}>
          <Icon.IonIcons5.IoTrashBinSharp
            title={t("playlist.settings.rules.restriction.delete")}
          />
        </div>
      )}
      <Input
        name="limit"
        placeholder={t("playlist.settings.rules.restriction.limit")}
        // label={
        //   <div className="playlist-restriction__label">
        //     {t("playlist.settings.rules.restriction.limit")}
        //   </div>
        // }
        before={
          <div className="playlist-restriction__field playlist-restriction__field--before">
            {t("playlist.settings.rules.restriction.max")}
          </div>
        }
        after={
          <div className="playlist-restriction__field playlist-restriction__field--after">
            {t("playlist.settings.rules.restriction.repetitions")}
          </div>
        }
        type="number"
        value={limit}
        min={1}
        max={99}
        onChange={onChangeLimit}
        onBlur={onBlur}
      />
      <Input
        name="factor"
        placeholder={t("playlist.settings.rules.restriction.factor")}
        // label={
        //   <div className="playlist-restriction__label">
        //     {t("playlist.settings.rules.restriction.factor")}
        //   </div>
        // }
        before={
          <div className="playlist-restriction__field playlist-restriction__field--before">
            {t("playlist.settings.rules.restriction.every")}
          </div>
        }
        type="number"
        value={factor}
        min={1}
        max={999}
        onChange={onChangeFactor}
        onBlur={onBlur}
      />
      <Select
        name="unit"
        placeholder={t("playlist.settings.rules.restriction.unit")}
        // label={
        //   <div className="playlist-restriction__label">
        //     {t("playlist.settings.rules.restriction.unit")}
        //   </div>
        // }
        options={UNITS}
        value={unit ?? undefined}
        onChange={onChangeUnit}
      />
    </div>
  );
};

export default PlaylistRestriction;
