import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const langEn = require("./locales/en.json");
const langDe = require("./locales/de.json");

const resources = {
  en: {
    translation: langEn,
  },
  de: {
    translation: langDe,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: navigator.language,
  fallbackLng: "en",
});

export default i18n;
