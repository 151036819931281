import {
  EmptyState,
  Input,
  Label,
  Option,
  Select,
  VirtualizedList,
} from "@quantumcast/ui";
import * as React from "react";
import Page from "../../components/layouts/Page/Page";
import AuthContext from "../../global/Auth/AuthContext";
import { t } from "../../global/Localization/translation";
import AddPlaylist from "./AddPlaylist/AddPlaylist";
import { Playlist } from "./domain";
import DeletePlaylist from "./PlaylistList/PlaylistListItem/DeletePlaylist/DeletePlaylist";
import { DeletePlaylistContext } from "./PlaylistList/PlaylistListItem/DeletePlaylist/DeletePlaylistContext";
import PlaylistListItem from "./PlaylistList/PlaylistListItem/PlaylistListItem";
import PlaylistListMeta from "./PlaylistList/PlaylistListMeta";
import "./Playlists.scss";
import PlaylistsContext from "./PlaylistsContext";

const Playlists = () => {
  const auth = React.useContext(AuthContext);
  const playlists = React.useContext(PlaylistsContext);
  const { deletePlaylistsService } = React.useContext(DeletePlaylistContext);
  const { send: sendEvent } = deletePlaylistsService;

  const onChangeLibrary = (name: string, option: Option) => {
    playlists.onChangeLibrary(option.value);
  };

  const onSearch = (e: React.FormEvent<HTMLInputElement>) => {
    playlists.onChangeSearch(e.currentTarget.value);
  };

  const onDelete = (playlist: Playlist) => {
    sendEvent({ type: "OPEN", playlist });
  };

  const isSingleLibrary = auth.libraries.length <= 1;

  const items = playlists.list.map((it) => ({
    key: it.id,
    node: (
      <PlaylistListItem
        key={it.id}
        library={playlists.library}
        playlist={it}
        onDelete={onDelete}
      />
    ),
  }));

  return (
    <Page width="narrow">
      <div className="playlists">
        <DeletePlaylist />
        <div className="playlists__header">
          <div className="playlists__libraries">
            <Select
              label={t("playlists.library.label")}
              name="library"
              value={{ label: playlists.library, value: playlists.library }}
              options={auth.libraries.map((it) => ({
                label: it,
                value: it,
              }))}
              isDisabled={isSingleLibrary}
              onChange={onChangeLibrary}
            />
          </div>
          <div className="playlists__search">
            <Input
              name="search"
              value={playlists.search}
              placeholder={t("playlists.search.placeholder")}
              label={<Label label={t("playlists.search.label")} />}
              isClearable={true}
              onChange={onSearch}
            />
          </div>
          <div className="playlists__add">
            <AddPlaylist />
          </div>
        </div>
        {!playlists.isLoading && (
          <PlaylistListMeta total={playlists.total} length={playlists.length} />
        )}
        <VirtualizedList
          isLoading={playlists.isLoading}
          items={items}
          itemDefaultHeight={60}
          emptyState={<EmptyState text={t("playlists.notFound")} />}
        />
        {!playlists.isLoading && !playlists.search && (
          <div className="playlists__footer">{t("playlists.footer")}</div>
        )}
      </div>
    </Page>
  );
};

export default Playlists;
