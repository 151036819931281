import { HttpError } from "@klw/fetch/lib/types";
import { assign, createMachine } from "xstate";

const deleteCueKeyModalMachine = createMachine({
    initial: 'closed',
    tsTypes: {} as import("./deleteCueKeyModalMachine.typegen").Typegen0,
    id: 'deleteCueKeyModalMachine',
    schema: {
        context: {} as {
            key?: string
        },
        events: {} as {
            type: 'open'
            key: string
        } | {
            type: 'close'
        } | {
            type: 'confirm'
        },
        services: {} as {
            deleteCueKey: {
                data: void | HttpError
            }
        }
    },
    context: {
        key: undefined
    },
    states: {
        closed: {
            on: {
                open: {
                    target: 'open',
                    actions: ['storeKey']
                }

            }
        },
        open: {
            on: {
                close: {
                    target: 'closed',
                    actions: ['discardKey']
                },
                confirm: {
                    target: 'deleting'
                }
            }
        },
        deleting: {
            invoke: {
                src: 'deleteCueKey',
                onDone: {
                    target: 'closed'
                },
                onError: {
                    target: 'open'
                }
            }
        }
    }
}, {
    actions: {
        storeKey: assign((context, event) => ({
            key: event.key
        })),
        discardKey: assign((context, event) => ({
            key: undefined
        }))
    }
})

export { deleteCueKeyModalMachine }